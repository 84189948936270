export const getpaymentOptions = (option, paymentOptions , Amount) => {
  const categorizeMethod = (type) => {
    return paymentOptions
      .filter((item) => item?.payOpt === type)
      .map((item) => JSON.parse(item[type]));
  };
  const categorizeMethodEmi = (type) => {
    return paymentOptions.filter((item) => item?.payOpt === type);
  };

  if (option == "Cards") {
    const CreditCard = categorizeMethod("OPTCRDC");
    const DebitCard = categorizeMethod("OPTDBCRD");

    return {
      DebitCard: DebitCard.length > 0 ? DebitCard[0] : [],
      CreditCard: CreditCard.length > 0 ? CreditCard[0] : [],
    };
  } else if (option == "NetBanking") {
    const NetBanking = categorizeMethod("OPTNBK");

    return {
      NetBanking: NetBanking.length > 0 ? NetBanking[0] : [],
    };
  } else if (option == "UPI") {
    const UPI = categorizeMethod("OPTUPI");

    return {
      UPI: UPI.length > 0 ? UPI[0] : [],
    };
  } else if (option == "EMI") {
    const EMI = categorizeMethodEmi("OPTEMI");

    // console.log("EMI is  :  ", EMI);

    let DDToReturn = {
      EMI:
        EMI.length > 0
          ? {
              Banks: JSON.parse(EMI[0].EmiBanks),
              Plans: JSON.parse(EMI[0].EmiPlans),
            }
          : {
              Banks: [],
              Plans: [],
            },
    };

    if (DDToReturn.EMI.Banks.length == 0) {
      return DDToReturn;
    }

    let DD = DDToReturn.EMI.Banks.map((bank, id) => {
      // Filter plans for the current bank
      const relevantPlans = DDToReturn.EMI.Plans.filter(
        (plan) => plan.planId === bank.planId
      );

      // Check for no-cost EMI (difference within Rs 100)
      const noCostEmiPlan = relevantPlans.find(
        (plan) => Math.abs(plan.total - Amount) < 100
      );

      // Find the minimum EMI percentage for the rest of the plans
      const minEmiPlan = relevantPlans.reduce((minPlan, currentPlan) => {
        const currentProcessingFee = parseFloat(
          currentPlan.processingFeePercent || 0
        );
        const minProcessingFee = parseFloat(minPlan.processingFeePercent || 0);
        return currentProcessingFee < minProcessingFee ? currentPlan : minPlan;
      }, relevantPlans[0]);

      return {
        ...bank,
        noCostEmiPlan,
        minEmiPlan,
      };
    });

    DDToReturn.EMI.Banks = DD;

    return DDToReturn;

  } else if (option == "Wallet") {
    const Wallet = categorizeMethod("OPTWLT");

    return {
      Wallet: Wallet.length > 0 ? Wallet[0] : [],
    };
  } else if (
    option == "GooglePay" ||
    option == "GiftCard" ||
    option === "PayLater"
  ) {
    return {
      option: [],
    };
  }
};
