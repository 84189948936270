import React, { useEffect, useState } from "react";
import "./MainPaymentMethods.css";
import { PaymentMethods } from "../../../Constants/PaymentMethods";
import images from "../../../Utils/importImages";
import CardPayment from "./Methods/CardPayment";
import NetBanking from "./Methods/NetBanking";
import UPI from "./Methods/UPI";
import EMI from "./Methods/EMI";
import Wallets from "./Methods/Wallets";
import GooglePay from "./Methods/GooglePay";
import GiftCard from "./Methods/GiftCard";
import PayLater from "./Methods/PayLater";
import jsonp from "jsonp";
import { excess_Key_test, main_excess_key } from "../../../Config/Keys";
import { MAIN_PAYMENT_OPTIONS_URL } from "../../../Config/Api";
import { useDispatch, useSelector } from "react-redux";
import { setpaymentOptions } from "../../../Redux/Slices/Main_HomePage_Slice/HomePage_SLICE";
import { getpaymentOptions } from "../../../Utils/PaymentFucntions";

function MainPaymentMethods({ Theme , Amount}) {

  let {paymentOptions} = useSelector((state) => state.HomePageMain);
  let dispatch = useDispatch();

  let [selectedMethod, setselectedMethod] = useState("Credit / Debit / ATM Cards");

  // Make sure processData is globally accessible
  const processData = (data) => {
    // console.log("Payment options:", data);
    dispatch(setpaymentOptions(data))
  };

  useEffect(() => {

    const currency = 'INR'; // Currency
    
    const url = `${MAIN_PAYMENT_OPTIONS_URL}transaction/transaction.do?command=getJsonData&access_code=${main_excess_key}&currency=${currency}&amount=${Amount}`;

    // Use jsonp with a globally accessible function
    jsonp(url, { param: 'callback', name: 'processData' }, (err, data) => {
      if (err) {
        console.error("Error fetching payment options:", err);
      } else {
        processData(data);
      }
    });
    
  }, []);

  // console.log("paymentOptions:", paymentOptions);

  return (
    <div className="payment-Methods">
      <div className={`heading ${Theme}_semibold font16 color_deep-ocean`}>
        PAYMENT METHOD
      </div>
      <div className="payment-content">
        <div className="methods">
          {PaymentMethods.map((item , id) => {
            return (
              <div className={`payment-method-item ${item.Name === selectedMethod && "selected"}`} onClick={() => setselectedMethod(item.Name)}>
                <div className="logo">
                  <img src={images[item.logo]} alt="" />
                </div>
                <div className="content">
                  <div className={`name ${Theme}_semibold color_${selectedMethod === id ? "endeavour" : "deep-ocean"} font16`}>{item.Name}</div>
                  <div className={`about ${Theme}_regular color_gravel font14`}>{item.Discription}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="gateway-arena">
          {
            selectedMethod == "Credit / Debit / ATM Cards" && <CardPayment Theme={Theme} Amount={Amount} paymentOptionsAvailable={getpaymentOptions("Cards" , paymentOptions , Amount)}/>
          }
          {
            selectedMethod == "Net Banking" && <NetBanking Theme={Theme} Amount={Amount} paymentOptionsAvailable={getpaymentOptions("NetBanking" , paymentOptions , Amount)} />
          }
          {
            selectedMethod == "UPI" && <UPI Theme={Theme} Amount={Amount} paymentOptionsAvailable={getpaymentOptions("UPI" , paymentOptions , Amount)}/>
          }
          {
            selectedMethod == "EMI" && <EMI Theme={Theme} Amount={Amount} paymentOptionsAvailable={getpaymentOptions("EMI" , paymentOptions , Amount)}/>
          }
          {
            selectedMethod == "Wallets" && <Wallets Theme={Theme} Amount={Amount} paymentOptionsAvailable={getpaymentOptions("Wallet" , paymentOptions , Amount)} />
          }
          {
            selectedMethod == "Google Pay" && <GooglePay Theme={Theme} Amount={Amount} paymentOptionsAvailable={getpaymentOptions("GooglePay" , paymentOptions , Amount)}/>
          }
          {
            selectedMethod == "Gift Card" && <GiftCard Theme={Theme} Amount={Amount} paymentOptionsAvailable={getpaymentOptions("GiftCard" , paymentOptions , Amount)}/>
          }
          {
            selectedMethod == "Pay Later" && <PayLater Theme={Theme} Amount={Amount} paymentOptionsAvailable={getpaymentOptions("PayLater" , paymentOptions , Amount)}/>
          }
        </div>
      </div>
    </div>
  );
}

export default MainPaymentMethods;
