import { createSlice } from "@reduxjs/toolkit";
import { callPaymentGateway, callPriceVerificationFlight, getPaymentData } from "../../Apis/Payment-api";

const initialState = {
  // gateway things
  payment_Status : "",

  payNowLoading : false,
  isOffline: false,
  Loading: true,
  paymentType: "",
  
  PaymentData : null,
  BookingData : null,
  paymentOptions : [],
  Theme  : "",

  // flight related 
  AncellariesPrice : {Seats : 0, Meals :  0 , Baggage : 0 },
  isPriceChanged : false ,
  PriceChangedData : {Message  : ""} ,
  OpenPopupMini: false,
  OpenPopupMiniType: "", 
  OpenPopupData: {},

  // error handling
  hasError: false,
  ErrorType: { Heading: "", Message: "" },

};

export const fetch_Payment = (location) => {
  return async (dispatch, getState) => {
    const statesData = getState();

    try {

        // Get the full path
      const urlPath = location.pathname; // e.g., /id=abc40582-29fb-49ed-8349-8930a27dfedb

      // Use regular expression to extract 'id' value from the URL path
      const match = urlPath.match(/id=([a-zA-Z0-9-]+)/);

      let id  = "";
      if (match && match[1]) {
        id = match[1];
      }

      // If no 'id' found in path, handle the error
      if (!id) {
        throw new Error("No id found in the URL");
      }

      let data = await getPaymentData(id);

      data.data.booking.flight_data = JSON.parse(data.data.booking.flight_data)
      data.data.booking.traveller_data = JSON.parse(data.data.booking.traveller_data)

      dispatch(setLoading(false));
      
      dispatch(setPaymentData(data.data.payment));
      dispatch(setBookingData(data.data.booking));
      
      let paymentType = data.data.payment.pay_type;
      
      if(paymentType == "official-flight"){
        
        
        dispatch(setTheme(data.data.booking.traveller_data.registered[0].Theme))
        dispatch(setpaymentType("Flight"))

        let ancellaryPrices = {
          Seats : 0,
          Meals : 0,
          Baggage : 0,
        }

        // update the ancellaries prices if there 
        if(data.data.booking.traveller_data.ancellariesCodes.Seats.length !== 0){
          data.data.booking.traveller_data.ancellariesCodes.Seats.forEach(element => {
            ancellaryPrices.Seats += element.Price
          });
        }
        if(data.data.booking.traveller_data.ancellariesCodes.Meals.length !== 0){
          data.data.booking.traveller_data.ancellariesCodes.Meals.forEach(element => {
            ancellaryPrices.Meals += element.Price
          });
        }
        if(data.data.booking.traveller_data.ancellariesCodes.Bags.length !== 0){
          data.data.booking.traveller_data.ancellariesCodes.Bags.forEach(element => {
            ancellaryPrices.Baggage += element.Price
          });
        }
        
        dispatch(setAncellariesPrice({Seats : ancellaryPrices.Seats, Meals :  ancellaryPrices.Meals , Baggage : ancellaryPrices.Baggage }));

      }

      // set the payment status 
      dispatch(setpayment_Status(data.data.payment.order_status))

    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
      dispatch(sethasError(true));
      dispatch(
        setErrorType({
          Heading: "Invalid Payment Link",
          Message:
            "The Payment Link is InValid please Go Back and start a anew payment ",
        })
      );
    }
  };
};

export const Clicked_PayNow = (Payment_type , Payment_paras) => {
  return async (dispatch, getState) => {
    const statesData = getState();

    let { HomePageMain } = statesData;

    let state = { ...HomePageMain };

    dispatch(setpayNowLoading(true))
    // check the payNo's Module 

    dispatch(setOpenPopupMini(true));
    dispatch(setOpenPopupMiniType("Loading"));
    dispatch(setOpenPopupData(null));

    let proceedTopay = false;
    
    if(state.paymentType == "Flight"){

      // call the api for final price verification

      try {
  
        let paramas = {
          BookingRefId: state.BookingData.booking_id_ref,
          SearchId: state.BookingData.search_id,
          OrderId: state.PaymentData.order_id
        }

        console.log("paramas is : " , paramas);
        
        let proceededData = await callPriceVerificationFlight(paramas)
  
        proceededData = proceededData.data;
        console.log("proceededData is : " , proceededData);
  
        if(proceededData.proceedToPhase2 == false){
          // show that reason 
          
          if(proceededData.code == "IVL" || proceededData.code == "FNA"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : "Link expired",  Data : "You can start your search again and complete your booking" , showContinue : false }));
          } if(proceededData.code == "SE"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : "Session expired",  Data : "You can start your search again and complete your booking" , showContinue : false}));
          } else if(proceededData.code == "TE" || proceededData.code == "TVA"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later" , showContinue : true , fetchFlight : true}));
          } else if(proceededData.code == "TVM"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : "Invalid Travellers",  Data : proceededData.Message , showContinue : true , fetchFlight : true}));
          } else if(proceededData.code == "RBTC"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : proceededData.CodeName,  Data : proceededData.Message , showContinue : true , fetchFlight : true}));
          } else if(proceededData.code === "ANCE"){
            dispatch(setOpenPopupMiniType("Error"));
            dispatch(setOpenPopupData({Heading : proceededData.CodeName,  Data : proceededData.Message , showContinue : true , fetchFlight : false , ProceedToPayName: 
              proceededData.CodeName.includes("Bag") ? "Bags" :
              proceededData.CodeName.includes("Seat") ? "Seats" :
              proceededData.CodeName.includes("Meal") ? "Meals" : proceededData.CodeName}));
          }  else if(proceededData.code === "PC"){
            dispatch(setOpenPopupMiniType("Price_Changed"));
            dispatch(
              setOpenPopupData(
                {Heading : proceededData.CodeName,  Message : `The Price of this Flight is ${proceededData.Message.priceStatus} . Old Price was Rs . ${proceededData.Message.oldPrice} , Current Price of the Flight is Rs . ${proceededData.Message.newPrice}` , showContinue : true,  fetchFlight : true}
              )
            );
          } 
  
        } else {
        
          // redirect to payment page 
          
          // calll the payment Gateway

          dispatch(setOpenPopupMiniType("Message"));
          dispatch(setOpenPopupData({Heading : "Redirecting .... ",  Data : "Redirecting You to Your Payment Method"}));
          dispatch(setpayNowLoading(false))
          
          proceedTopay = true;
          
        }
        
      } catch (e) {
        
      console.log("the erorr proceed toi pay here is : " , e);
      
      dispatch(setOpenPopupMiniType("Error"));
      dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later"}));
      dispatch(setpayNowLoading(false))

      }
    
    }
  
    if(proceedTopay == true){

      // call the paym,ent gateway along with the payment method 

      alert("calling payment gteway here ");

      let paymentParasAll = {};

      if(Payment_type === "Credit Card"){

        paymentParasAll = {
          ...Payment_paras,
          order_id : state.PaymentData.order_id,
          amount : state.PaymentData.amount,
          currency : "INR",
          payment_type : "Credit Card",
          payment_option : 'OPTCRDC',
        }
      } else if(Payment_type === "Debit Card"){

        paymentParasAll = {
          ...Payment_paras,
          order_id : state.PaymentData.order_id,
          amount : state.PaymentData.amount,
          currency : "INR",
          payment_type : "Debit Card",
          payment_option : 'OPTDBCRD',
        }
      } else if(Payment_type === "Net Banking"){

        paymentParasAll = {
          ...Payment_paras,
          order_id : state.PaymentData.order_id,
          amount : state.PaymentData.amount,
          currency : "INR",
          payment_type : "Net Banking",
          payment_option : 'OPTNBK',
        }
      } else if(Payment_type === "Wallet"){

        paymentParasAll = {
          ...Payment_paras,
          order_id : state.PaymentData.order_id,
          amount : state.PaymentData.amount,
          currency : "INR",
          payment_type : "Wallet",
          payment_option : 'OPTWLT',
        }
      }  else if(Payment_type === "Wallet"){

        paymentParasAll = {
          ...Payment_paras,
          order_id : state.PaymentData.order_id,
          amount : state.PaymentData.amount,
          currency : "INR",
          payment_type : "Wallet",
          payment_option : 'OPTWLT',
        }
      }  else if(Payment_type === "EMI"){

        paymentParasAll = {
          ...Payment_paras,
          order_id : state.PaymentData.order_id,
          amount : state.PaymentData.amount,
          currency : "INR",
          payment_type : "EMI",
          payment_option : 'OPTEMI',
        }
      }

      try{

       let dd = await callPaymentGateway(paymentParasAll);

       dd = dd.data;

       console.log("dd is : " , dd);
       

       if(dd.redirectToUrl == false){
         if(dd.code == "IVL" || dd.code == "FNA"){
          dispatch(setOpenPopupMiniType("Error"));
          dispatch(setOpenPopupData({Heading : "Link expired",  Data : "You can start your search again and complete your booking" , showContinue : false }));
        } if(dd.code == "SE"){
          dispatch(setOpenPopupMiniType("Error"));
          dispatch(setOpenPopupData({Heading : "Session expired",  Data : "You can start your search again and complete your booking" , showContinue : false}));
        } else if(dd.code == "TE" || dd.code == "TVA"){
          dispatch(setOpenPopupMiniType("Error"));
          dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later" , showContinue : true , fetchFlight : true}));
        }
       } else {

        let UrlToRedirect = dd.url;
  
        window.location.href = `${UrlToRedirect}`;

       }
      } catch (e){
        console.log("the erorr proceed toi pay here is : " , e);
      
        dispatch(setOpenPopupMiniType("Error"));
        dispatch(setOpenPopupData({Heading : "Error",  Data : "Technical Error ! Please Try Again Later"}));
        dispatch(setpayNowLoading(false))
      }

    }


    
  };
};

const HomePage_SLICE = createSlice({
  name: "HomePageSearchBarFlight",
  initialState,
  reducers: {
    sethasError: (state, action) => {
      state.hasError = action.payload;
    },
    setErrorType: (state, action) => {
      state.ErrorType = action.payload;
    },
    setLoading: (state, action) => {
      state.Loading = action.payload;
    },
    setLoading: (state, action) => {
      state.Loading = action.payload;
    },
    setpaymentType: (state, action) => {
      state.paymentType = action.payload;
    },
    setpayNowLoading: (state, action) => {
      state.payNowLoading = action.payload;
    },
    setPaymentData: (state, action) => {
      state.PaymentData = action.payload;
    },
    setBookingData: (state, action) => {
      state.BookingData = action.payload;
    },
    setTheme: (state, action) => {
      state.Theme = action.payload;
    },
    setAncellariesPrice: (state, action) => {
      state.AncellariesPrice = action.payload;
    },
    setpaymentOptions: (state, action) => {
      state.paymentOptions = action.payload;
    },

    setOpenPopupMini: (state, action) => {
      state.OpenPopupMini = action.payload;
    },
    setOpenPopupMiniType: (state, action) => {
      state.OpenPopupMiniType = action.payload;
    },
    setOpenPopupData: (state, action) => {
      state.OpenPopupData = action.payload;
    },
    setpayment_Status: (state, action) => {
      state.payment_Status = action.payload;
    },

  },
});

export const { sethasError, setErrorType, setpayNowLoading , setLoading, setpaymentType , setPaymentData , setBookingData , setTheme , setAncellariesPrice , setpaymentOptions , 

  setOpenPopupMini,
  setOpenPopupMiniType,
  setOpenPopupData ,
  setpayment_Status

} =
  HomePage_SLICE.actions;

export default HomePage_SLICE.reducer;
