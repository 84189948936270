import React from "react";
import images from "../../../../../Utils/importImages";

function AirlinesLogContainer({ allAirlines }) {

  return (
    <>

      {allAirlines?.length === 4 && (
        <div className="four-img-container frame-174-7">
          <div>
            <div style={{ display: "flex" }}>
              <div className="frame-223-2">
                <img
                  className="vistara_-logo-1-1"
                  src={(allAirlines?.[0].logo === "" || allAirlines?.[0].logo === undefined) ? images["default-airline.png"] : allAirlines?.[0].logo}
                  alt={allAirlines?.[0].name}
                />
              </div>
              <div className="frame-223-2">
                <img
                  className="vistara_-logo-1-1"
                   src={(allAirlines?.[1].logo === "" || allAirlines?.[1].logo === undefined) ? images["default-airline.png"] : allAirlines?.[1].logo}
                  alt={allAirlines?.[1].name}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="frame-223-2">
                <img
                  className="vistara_-logo-1-1"
                   src={(allAirlines?.[2].logo === "" || allAirlines?.[2].logo === undefined) ? images["default-airline.png"] : allAirlines?.[2].logo}
                  alt={allAirlines?.[2].name}
                />
              </div>
              <div className="frame-223-2">
                <img
                  className="vistara_-logo-1-1"
                   src={(allAirlines?.[3].logo === "" || allAirlines?.[3].logo === undefined) ? images["default-airline.png"] : allAirlines?.[3].logo}
                  alt={allAirlines?.[3].name}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {allAirlines?.length === 3 && (
        <div className="three-img-container frame-174-7">
          <div className="frame-223-2">
            <img
              className="vistara_-logo-1-1"
      src={(allAirlines?.[0].logo === "" || allAirlines?.[0].logo === undefined) ? images["default-airline.png"] : allAirlines?.[0].logo}
      alt={allAirlines?.[0].name}
            />
          </div>
          <div className="frame-224-4">
            <div className="frame-173-4">
              <img
                className="unnamed"
        src={(allAirlines?.[1].logo === "" || allAirlines?.[1].logo === undefined) ? images["default-airline.png"] : allAirlines?.[1].logo}
        alt={allAirlines?.[1].name}
              />
            </div>
          </div>
          <div className="frame-223-2">
            <div className="frame-224">
              <img
                className="png-transparent-luft-5 png-transparent-luft-6"
                src={(allAirlines?.[2].logo === "" || allAirlines?.[2].logo === undefined) ? images["default-airline.png"] : allAirlines?.[2].logo}
                alt={allAirlines?.[2].name}              />
            </div>
          </div>
        </div>
      )}

      {allAirlines?.length === 2 && (
        <div className="two-img-container">
          <div className="frame-233-5">
            <img
              className="turkish-airlines-symbol-1-1"
              src={(allAirlines?.[0].logo === "" || allAirlines?.[0].logo === undefined) ? images["default-airline.png"] : allAirlines?.[0].logo}

              alt={allAirlines?.[0].name}      
            />
          </div>
          <div className="frame-234-1">
            <img
              className="png-transparent-luft-4 png-transparent-luft-6"
              src={(allAirlines?.[1].logo === "" || allAirlines?.[1].logo === undefined) ? images["default-airline.png"] : allAirlines?.[1].logo}

              alt={allAirlines?.[1].name}                  />
          </div>
        </div>
      )}

      {allAirlines?.length === 1 && (
        <img
          className="single-img-container png-transparent-luft-5 png-transparent-luft-6"
          src={(allAirlines?.[0].logo === "" || allAirlines?.[0].logo === undefined) ? images["default-airline.png"] : allAirlines?.[0].logo}

          alt={allAirlines?.[0].name}          />
      )}
    </>
  );
}

export default AirlinesLogContainer;
