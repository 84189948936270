import React, { useEffect, useState } from "react";
import "./EMI.css";
import { InputAdornment, SvgIcon, TextField } from "@mui/material";
import EditBar from "./Components/EditBar";
import images from "../../../../Utils/importImages";
import OptionsSelector from "./Components/OptionsSelector";
import YearSelector from "./Components/YearSelector";
import MonthSelector from "./Components/MonthSelector";
import { useDispatch, useSelector } from "react-redux";
import { Clicked_PayNow } from "../../../../Redux/Slices/Main_HomePage_Slice/HomePage_SLICE";

// Search Icon
const CustomSearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8253 14.3821C8.39736 14.3821 5.61843 11.6031 5.61843 8.17516C5.61843 4.74718 8.39736 1.96826 11.8253 1.96826C15.2533 1.96826 18.0322 4.74718 18.0322 8.17516C18.0322 11.6031 15.2533 14.3821 11.8253 14.3821Z"
      stroke="#626262"
      strokeWidth="1.37931"
      strokeMiterlimit="10"
    />
    <path
      d="M7.2749 12.7249L1.96801 18.0317"
      stroke="#626262"
      strokeWidth="1.37931"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// Expand Icon
function ExpandIcon(props) {
  return (
    <SvgIcon {...props} style={{ color: "black", fontSize: props.fontSize }}>
      <path d={props.d} />
    </SvgIcon>
  );
}

function EMI({ Theme, Amount, paymentOptionsAvailable }) {
  // console.log("paymentOptionsAvailable is : ", paymentOptionsAvailable);

  let { payNowLoading } = useSelector((state) => state.HomePageMain);

  let [continueButtonError, setcontinueButtonError] = useState(false);
  let [continueButtonErrorMessage, setcontinueButtonErrorMessage] =
    useState(false);

  const [selectedEmiMethod, setSelectedEmiMethod] = useState(null);
  const [selectedEmiMethodError, setSelectedEmiMethodError] = useState("");

  const [selectedEmiPlan, setSelectedEmiPlan] = useState(null);
  const [selectedEmiPlanError, setSelectedEmiPlanError] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [filteredBanks, setFilteredBanks] = useState(
    paymentOptionsAvailable?.EMI?.Banks || []
  ); // State for filtered banks

  console.log("filteredBanks is : ", filteredBanks);

  console.log("selectedEmiMethod is : ", selectedEmiMethod);

  // Debounce logic with useEffect
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Filter banks based on search term
      const filteredBanks = paymentOptionsAvailable?.EMI?.Banks.filter((bank) =>
        bank.gtwName.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // console.log("filteredBanksis : ", filteredBanks);

      setFilteredBanks(filteredBanks || []);
    }, 300); // 300ms debounce delay

    return () => clearTimeout(delayDebounceFn); // Cleanup on unmount or searchTerm change
  }, [searchTerm, paymentOptionsAvailable]);

  const onChangeEmiMethod = (event) => {
    setSelectedEmiMethodError("");
    setSearchTerm(event.target.value); // Update search term on change
  };

  const validateEMIMethod = () => {
    // Validation logic
  };

  let onClickEdit = () => {
    setSelectedEmiMethod(null);
    setSelectedEmiPlan(null);
  };
  let onClickEdit2 = () => {
    setSelectedEmiPlan(null);
  };

  useEffect(() => {
    let timeoutId;

    if (continueButtonError === true) {
      // Set a timeout to dispatch the action after 2 seconds
      timeoutId = setTimeout(() => {
        setcontinueButtonError(false);
      }, 2000); // 2000 milliseconds = 2 seconds
    }

    // Clean up the timeout if the component unmounts or the condition changes
    return () => clearTimeout(timeoutId);
  }, [continueButtonError]);

  let dispatch = useDispatch();

  let [CardOption, setCardOption] = useState(0);

  let [CardNumber, setCardNumber] = useState("");
  let [CardHolderName, setCardHolderName] = useState("");
  let [CardHolderBankname, setCardHolderBankname] = useState("");
  let [CardName, setCardName] = useState("");
  let [ExpiryMonth, setExpiryMonth] = useState("");
  let [ExpiryYear, setExpiryYear] = useState("");
  let [CVV, setCVV] = useState("");

  // console.log("ExpiryYear is : " , ExpiryYear);

  let [CardNumberError, setCardNumberError] = useState("");
  let [CardHolderNameError, setCardHolderNameError] = useState("");
  let [CardHolderBanknameError, setCardHolderBanknameError] = useState("");
  let [CardNameError, setCardNameError] = useState("");
  let [ExpiryMonthError, setExpiryMonthError] = useState("");
  let [ExpiryYearError, setExpiryYearError] = useState("");
  let [CVVError, setCVVError] = useState("");

  let onChangeCardNumber = (info) => {
    setCardNumberError("");
    setCardNumber(info.target.value);
  };

  const validateCardNumber = () => {
    if (CardNumber === "") {
      setCardNumberError("Card Number Can't be Empty");
      return false;
    } else if (CardNumber.length > 20) {
      setCardNumberError("Card Number max should contain 20 digits");
      return false;
    }
    return true;
  };

  let onChangeCardHolderName = (info) => {
    setCardHolderNameError("");
    setCardHolderName(info.target.value);
  };

  const validateCardHolderName = () => {
    if (CardHolderName === "") {
      setCardHolderNameError("Card Holder Name Can't be Empty");
      return false;
    } else if (!/^[A-Za-z ]+$/.test(CardHolderName)) {
      setCardHolderNameError(
        "Name on Card should contain only alphabets and spaces"
      );
      return false;
    }
    return true;
  };

  let onChangeCardHolderBankname = (info) => {
    setCardHolderBanknameError("");
    setCardHolderBankname(info.target.value);
  };

  const validateCardHolderBankName = () => {
    if (CardHolderBankname === "") {
      setCardHolderBanknameError("Card's Bank Name Can't be Empty");
      return false;
    } else if (!/^[A-Za-z ]+$/.test(CardHolderBankname)) {
      setCardHolderBanknameError(
        "Card's Bank should contain only alphabets and spaces"
      );
      return false;
    }
    return true;
  };

  let onChangeCardName = (info) => {
    setCardNameError("");
    setCardName(info == null ? "" : info.cardName);
    // console.log("info is : ", info);
  };

  const validateCardName = () => {
    if (CardName === "") {
      setCardNameError("Card Name Can't be Empty");
      return false;
    }
    return true;
  };

  let [clickedInsideMonth, setClickedInsideMonth] = useState(false);
  let [callMonthValidator, setcallMonthValidator] = useState(false);

  useEffect(() => {
    if (clickedInsideMonth == true && callMonthValidator == true) {
      validateMonth(ExpiryMonth);
    }
  }, [clickedInsideMonth, callMonthValidator]);

  let onChangeExpiryMonth = (info) => {
    setExpiryMonth(info);
    setExpiryMonthError("");
  };

  const validateMonth = () => {
    if (ExpiryMonth === "") {
      setExpiryMonthError("Expiry Month Can't be Empty");
      return false;
    }
    return true;
  };

  let [clickedInsideYear, setClickedInsideYear] = useState(false);
  let [callYearValidator, setcallYearValidator] = useState(false);

  useEffect(() => {
    if (clickedInsideYear == true && callYearValidator == true) {
      validateYear(ExpiryYear);
    }
  }, [clickedInsideYear, callYearValidator]);

  let onChangeExpiryYear = (info) => {
    setExpiryYear(info);
    setExpiryYearError("");
  };

  const validateYear = () => {
    if (ExpiryYear === "") {
      setExpiryYearError("Expiry Year Can't be Empty");
      return false;
    }
    return true;
  };

  let onChangeCVVNumber = (info) => {
    setCVVError("");
    setCVV(info.target.value);
  };

  const validateCVVNumber = () => {
    if (CVV === "") {
      setCVVError("Card CVV Can't be Empty");
      return false;
    } else if (!/^\d{3}$/.test(CVV)) {
      setCVVError("CVV Number should be of 3 digits");
      return false;
    }
    return true;
  };

  let validateCardExpiry = (expiryMonth, expiryYear) => {
    // Get the current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-based, so we add 1

    // Convert expiryYear to a full year (e.g., '23' to '2023')
    const fullExpiryYear =
      expiryYear.length === 2 ? "20" + expiryYear : expiryYear;

    // Compare the year and month
    if (fullExpiryYear < currentYear) {
      console.log("Card is expired");
      return false; // Card is expired
    } else if (fullExpiryYear == currentYear && expiryMonth < currentMonth) {
      console.log("Card is expired");
      return false; // Card is expired
    }

    console.log("Card is valid");
    return true; // Card is valid
  };

  let validateCardNumberAdvance = (cardNumber) => {
    const cardPatterns = [
      { name: "Visa", pattern: /^4[0-9]{12}(?:[0-9]{3})?$/ }, // Visa cards
      { name: "MasterCard", pattern: /^5[1-5][0-9]{14}$/ }, // MasterCard
      { name: "AmEx", pattern: /^3[47][0-9]{13}$/ }, // American Express
      { name: "Discover", pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/ }, // Discover
      { name: "JCB", pattern: /^(?:2131|1800|35\d{3})\d{11}$/ }, // JCB
      { name: "Diners Club", pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/ }, // Diners Club
      { name: "RuPay", pattern: /^6(?:0|5|8)[0-9]{14}$/ }, // RuPay
      { name: "Maestro", pattern: /^(?:5[0678]|6[0-9])[0-9]{12,19}$/ }, // Maestro
      { name: "UnionPay", pattern: /^62[0-9]{14,17}$/ }, // UnionPay
      { name: "Mir", pattern: /^220[0-4][0-9]{12}$/ }, // Mir
    ];

    // Iterate over the card patterns to match the card number
    for (let card of cardPatterns) {
      if (card.pattern.test(cardNumber)) {
        console.log(`Valid card detected: ${card.name}`);
        return true; // Card is valid
      }
    }

    console.log("Invalid card number");
    return false; // No valid pattern was matched
  };

  let validateEmiOption = () => {
    // Validation logic
    if (selectedEmiMethod == null) {
      setcontinueButtonError(true);
      setcontinueButtonErrorMessage(
        " Please Select a EMI Option to Continue .."
      );
      return false;
    }

    return true;
  };

  let validateEmiPlan = () => {
    // Validation logic
    if (selectedEmiPlan == null) {
      setcontinueButtonError(true);
      setcontinueButtonErrorMessage(" Please Select a EMI Plan to Continue ..");
      return false;
    }

    return true;
  };

  let submitForm = () => {
    // validate the emi options

    let validateOption = validateEmiOption();

    if (validateOption == true) {
      let validatePlan = validateEmiPlan();

      if (validatePlan == true) {
        // validate the card payment

        let isValidatedCardNumber = validateCardNumber();
        let isValidatedCardHolderName = validateCardHolderName();
        let isValidatedCardsBankName = validateCardHolderBankName();
        let isValidCardName = validateCardName();
        let isValidMonth = validateMonth();
        let isValidYear = validateYear();
        let isValidCVVNumber = validateCVVNumber();

        if (
          isValidatedCardNumber &&
          isValidatedCardHolderName &&
          isValidatedCardsBankName &&
          isValidCardName &&
          isValidMonth &&
          isValidYear &&
          isValidCVVNumber
        ) {
          // do advance validation

          // 1 . validate card Number

          let isCardValidatedAdvance = validateCardNumberAdvance(CardNumber);
          if (isCardValidatedAdvance == false) {
            setCardNumberError("Invalid Card Number");
            return;
          }
          setCardNumberError("");

          let isCardNotExpired = validateCardExpiry(ExpiryMonth, ExpiryYear);
          if (isCardNotExpired == false) {
            setExpiryMonthError("Card is Expired");
            setExpiryYearError(" ");
            return;
          }
          setExpiryMonthError("");
          setExpiryYearError("");

          let CardParas = {
            card_type: CardOption == 0 ? "CRDC" : "DBCRD",
            card_name: CardName,
            card_number: CardNumber,
            expiry_month: ExpiryMonth,
            emi_plan_id : selectedEmiPlan.planId,
            emi_tenure_id : selectedEmiPlan.tenureId,
            expiry_year: ExpiryYear,
            cvv_number: CVV,
            issuing_bank: CardHolderBankname,
          };

          dispatch(
            Clicked_PayNow(
              "EMI",
              CardParas
            )
          );
        }
      }
      
    }
  };

  return (
    <div className="emi-Container">
      {selectedEmiMethod == null && (
        <TextField
          autoComplete="off"
          onBlur={validateEMIMethod}
          error={!!selectedEmiMethodError}
          helperText={selectedEmiMethodError}
          value={searchTerm}
          onChange={onChangeEmiMethod} // Handle change
          id="outlined-basic"
          label="Search Your Emi Method"
          variant="outlined"
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              fontSize: "16px",
              fontFamily: `var(--font-family-${
                Theme === "proxima"
                  ? `${Theme}_nova-regular`
                  : `${Theme}-regular`
              })`,
              fontStyle: "normal",
              fontWeight: "400",
              "&.Mui-focused": {
                color: Theme === "averta" ? "#C2A07C" : "#0057B7", // Color when focused
              },
            },
            "& .MuiOutlinedInput-root": {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CustomSearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}

      <EditBar
        Theme={Theme}
        Heading={
          filteredBanks.length == 0
            ? "EMI METHOD NOT AVAILABLE"
            : selectedEmiMethod == null
            ? "SELECT YOUR EMI METHOD"
            : "SELECTED EMI METHOD"
        }
        showEdit={selectedEmiMethod !== null}
        onClickEdit={onClickEdit}
      />

      {selectedEmiMethod == null ? (
        <div className="banks-container">
          <div className="banks-container">
            {filteredBanks.length > 0 &&
              filteredBanks.map((bank, id) => {
                return (
                  <div
                    className="bank"
                    key={id}
                    onClick={() => setSelectedEmiMethod(bank)}
                  >
                    <div className="method-info">
                      <img
                        src={
                          images[
                            bank.gtwName === selectedEmiMethod?.gtwName
                              ? "selected-blue-payment.svg"
                              : "non-selected-blue-payment.svg"
                          ]
                        }
                        alt=""
                      />
                      <ExpandIcon
                        fontSize="medium"
                        d={
                          "M4 10h3v7H4zm6.5 0h3v7h-3zM2 19h20v3H2zm15-9h3v7h-3zm-5-9L2 6v2h20V6z"
                        }
                      />
                      <div
                        className={`${Theme}_regular font16 color_deep-ocean`}
                      >
                        {bank.gtwName}
                      </div>
                    </div>
                    <div className="emi-details">
                      {bank.noCostEmiPlan ? (
                        <div
                          className={`no-cost ${Theme}_regular font13 color_white`}
                        >
                          No cost EMI
                        </div>
                      ) : (
                        <div
                          className={`${Theme}_semibold font14 color_deep-ocean`}
                        >
                          {bank.minEmiPlan.processingFeePercent} % p.a
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="banks-container" style={{ border: "none" }}>
          <div className="bank">
            <div className="method-info">
              <img src={images["selected-blue-payment.svg"]} alt="" />
              <ExpandIcon
                fontSize="medium"
                d={
                  "M4 10h3v7H4zm6.5 0h3v7h-3zM2 19h20v3H2zm15-9h3v7h-3zm-5-9L2 6v2h20V6z"
                }
              />
              <div className={`${Theme}_regular font16 color_deep-ocean`}>
                {selectedEmiMethod.gtwName}
              </div>
            </div>
            <div className="emi-details">
              {selectedEmiMethod.noCostEmiPlan ? (
                <div className={`no-cost ${Theme}_regular font13 color_white`}>
                  No cost EMI
                </div>
              ) : (
                <div className={`${Theme}_semibold font14 color_deep-ocean`}>
                  {selectedEmiMethod.minEmiPlan.processingFeePercent} % p.a
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {selectedEmiMethod !== null && (
        <EditBar
          Theme={Theme}
          Heading={
            selectedEmiPlan == null
              ? "SELECT YOUR EMI PLAN"
              : "SELECTED EMI PLAN"
          }
          showEdit={selectedEmiPlan !== null}
          onClickEdit={onClickEdit2}
        />
      )}

      {selectedEmiMethod !== null && (
        <div className="emi-plans">
          <div className="head-row">
            <div
              className={`row-content ${Theme}_semibold color_deep-ocean font15`}
            >
              Duration
            </div>
            <div
              className={`row-content ${Theme}_semibold color_deep-ocean font15`}
            >
              Interest Rate
            </div>
            <div
              className={`row-content ${Theme}_semibold color_deep-ocean font15`}
            >
              EMI
            </div>
            <div
              className={`row-content ${Theme}_semibold color_deep-ocean font15`}
            >
              Interest
            </div>
            <div
              className={`row-content ${Theme}_semibold color_deep-ocean font15`}
            >
              Total
            </div>
          </div>

          {selectedEmiPlan == null ? (
            <>
              {paymentOptionsAvailable.EMI.Plans.filter(
                (item) => item.planId === selectedEmiMethod.planId
              ).map((item, id) => {
                const interestAmount =
                  Math.ceil(item.total) - Math.ceil(Amount); // Interest amount
                const interestPercentage = (
                  (interestAmount / Amount) *
                  100
                ).toFixed(2); // Interest in %

                return (
                  <div
                    className="content-row"
                    onClick={() => setSelectedEmiPlan(item)}
                  >
                    <div
                      className={`row-content  ${Theme}_regular color_deep-ocean font16`}
                    >
                      <div className="sel-mon">
                        <img
                          src={
                            images[
                              item.tenureId === selectedEmiPlan?.tenureId
                                ? "selected-blue-payment.svg"
                                : "non-selected-blue-payment.svg"
                            ]
                          }
                          alt=""
                        />
                        {item.tenureMonths} Months
                      </div>
                    </div>
                    <div
                      className={`row-content ${Theme}_regular color_deep-ocean font16`}
                    >
                      {`${interestPercentage}% p.a`}
                    </div>
                    <div
                      className={`row-content ${Theme}_regular color_deep-ocean font16`}
                    >
                      <img src={images["rupee-bank.svg"]} alt="" />{" "}
                      {Math.ceil(item.emiAmount)}
                    </div>
                    <div
                      className={`row-content ${Theme}_regular color_deep-ocean font16`}
                    >
                      <img src={images["rupee-bank.svg"]} alt="" />{" "}
                      {interestAmount}
                    </div>
                    <div
                      className={`row-content ${Theme}_regular color_deep-ocean font16`}
                    >
                      <img src={images["rupee-bank.svg"]} alt="" />{" "}
                      {Math.ceil(item.total)}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="content-row">
                <div
                  className={`row-content  ${Theme}_regular color_deep-ocean font16`}
                >
                  <div className="sel-mon">
                    <img src={images["selected-blue-payment.svg"]} alt="" />
                    {selectedEmiPlan.tenureMonths} Months
                  </div>
                </div>
                <div
                  className={`row-content ${Theme}_regular color_deep-ocean font16`}
                >
                  {`${(
                    ((Math.ceil(selectedEmiPlan.total) - Math.ceil(Amount)) /
                      Amount) *
                    100
                  ).toFixed(2)}% p.a`}
                </div>
                <div
                  className={`row-content ${Theme}_regular color_deep-ocean font16`}
                >
                  <img src={images["rupee-bank.svg"]} alt="" />{" "}
                  {Math.ceil(selectedEmiPlan.emiAmount)}
                </div>
                <div
                  className={`row-content ${Theme}_regular color_deep-ocean font16`}
                >
                  <img src={images["rupee-bank.svg"]} alt="" />{" "}
                  {Math.ceil(selectedEmiPlan.total) - Math.ceil(Amount)}
                </div>
                <div
                  className={`row-content ${Theme}_regular color_deep-ocean font16`}
                >
                  <img src={images["rupee-bank.svg"]} alt="" />{" "}
                  {Math.ceil(selectedEmiPlan.total)}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {selectedEmiPlan !== null && (
        <>
          <div className={`emi-msg ${Theme}_regular font15 color_deep-ocean`}>
            Rs.{selectedEmiPlan.emiProcessingFee}+GST will be charged by Card
            issuer as one-time processing fee. T&C
          </div>

          <EditBar
            Theme={Theme}
            Heading={"ENTER YOUR CARD DETAILS"}
            showEdit={false}
            onClickEdit={null}
          />

          <div className="card-types">
            <div className="card-option" onClick={() => setCardOption(0)}>
              <img
                src={
                  images[
                    CardOption === 0
                      ? "selected-blue-payment.svg"
                      : "non-selected-blue-payment.svg"
                  ]
                }
                alt=""
              />
              <div className={`${Theme}_semibold color_deep-ocean font14`}>
                Credit Card
              </div>
            </div>
            <div className="card-option" onClick={() => setCardOption(1)}>
              <img
                src={
                  images[
                    CardOption === 1
                      ? "selected-blue-payment.svg"
                      : "non-selected-blue-payment.svg"
                  ]
                }
                alt=""
              />
              <div className={`${Theme}_semibold color_deep-ocean font14`}>
                Debit Card / ATM Card
              </div>
            </div>
          </div>

          <TextField
            autoComplete="off"
            onBlur={validateCardNumber} // Validate on blur
            error={!!CardNumberError}
            helperText={CardNumberError}
            value={CardNumber}
            onChange={onChangeCardNumber}
            id="outlined-basic"
            label="Card Number"
            variant="outlined"
            sx={{
              width: "100%",
              "& .MuiInputLabel-root": {
                fontSize: "16px",
                fontFamily: `var(--font-family-${
                  Theme == "proxima"
                    ? `${Theme}_nova-regular`
                    : `${Theme}-regular`
                })`,
                fontStyle: "normal",
                fontWeight: "400",
                "&.Mui-focused": {
                  color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                },
              },
            }}
          />

          <TextField
            autoComplete="off"
            onBlur={validateCardHolderName} // Validate on blur
            error={!!CardHolderNameError}
            helperText={CardHolderNameError}
            value={CardHolderName}
            onChange={onChangeCardHolderName}
            id="outlined-basic"
            label="Card Holder Name"
            variant="outlined"
            sx={{
              width: "100%",
              "& .MuiInputLabel-root": {
                fontSize: "16px",
                fontFamily: `var(--font-family-${
                  Theme == "proxima"
                    ? `${Theme}_nova-regular`
                    : `${Theme}-regular`
                })`,
                fontStyle: "normal",
                fontWeight: "400",
                "&.Mui-focused": {
                  color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                },
              },
              "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                },
              },
            }}
          />

          <div className="card-type-row">
            <TextField
              autoComplete="off"
              onBlur={validateCardHolderBankName} // Validate on blur
              error={!!CardHolderBanknameError}
              helperText={CardHolderBanknameError}
              value={CardHolderBankname}
              onChange={onChangeCardHolderBankname}
              id="outlined-basic"
              label="Card's Bank Name"
              variant="outlined"
              sx={{
                width: "50%",
                "& .MuiInputLabel-root": {
                  fontSize: "16px",
                  fontFamily: `var(--font-family-${
                    Theme == "proxima"
                      ? `${Theme}_nova-regular`
                      : `${Theme}-regular`
                  })`,
                  fontStyle: "normal",
                  fontWeight: "400",
                  "&.Mui-focused": {
                    color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                },
              }}
            />

            <OptionsSelector
              width="50%"
              onChange={onChangeCardName}
              value={CardName}
              onBlurFunc={validateCardName}
              Error={CardNameError}
              Theme={Theme}
              options={
                selectedEmiMethod.midProcesses.split("|").length == 1
                  ? [{ cardName: selectedEmiMethod.gtwName, status: "ACTI" }]
                  : selectedEmiMethod.midProcesses
                      .split("|")
                      .map((item) => ({ cardName: item, status: "ACTI" }))
              }
              showIcon={true}
              showBottomIcon={false}
              name={"Card Name"}
              IconComponent={
                <ExpandIcon
                  d={"M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"}
                  color={Theme == "proxima" ? "#0057B7" : "#976c41"}
                  fontSize="medium"
                />
              }
              mainkey={"cardName"}
            />
          </div>

          <div className="bottom-inputs">
            <MonthSelector
              clickedInside={clickedInsideMonth}
              setClickedInside={setClickedInsideMonth}
              SetCallValidator={setcallMonthValidator}
              Theme={Theme}
              Heading={"Expiry Month"}
              onBlur={validateMonth} // Validate on blur
              error={!!ExpiryMonthError}
              helperText={ExpiryMonthError}
              value={ExpiryMonth}
              onChange={onChangeExpiryMonth}
            />

            <YearSelector
              clickedInside={clickedInsideYear}
              setClickedInside={setClickedInsideYear}
              SetCallValidator={setcallYearValidator}
              Theme={Theme}
              Heading={"Expiry Year"}
              onBlur={validateYear} // Validate on blur
              error={!!ExpiryYearError}
              helperText={ExpiryYearError}
              value={ExpiryYear}
              onChange={onChangeExpiryYear}
            />

            <TextField
              autoComplete="off"
              onBlur={validateCVVNumber} // Validate on blur
              error={!!CVVError}
              helperText={CVVError}
              value={CVV}
              onChange={onChangeCVVNumber}
              id="outlined-basic"
              label="CVV Number"
              variant="outlined"
              sx={{
                width: "100%",
                "& .MuiInputLabel-root": {
                  fontSize: "16px",
                  fontFamily: `var(--font-family-${
                    Theme == "proxima"
                      ? `${Theme}_nova-regular`
                      : `${Theme}-regular`
                  })`,
                  fontStyle: "normal",
                  fontWeight: "400",
                  "&.Mui-focused": {
                    color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
                  },
                },
              }}
            />
          </div>
        </>
      )}

      {continueButtonError == true && (
        <div className={`emi-msg-red ${Theme}_regular font15 color_white`}>
          {continueButtonErrorMessage}
        </div>
      )}

      {/* Amount Row */}
      <div className="amount-row">
        <div className="amount">
          <img src={images["rupee-sign-big.svg"]} alt="" />
          <div className={`value ${Theme}_bold color_deep-ocean font24`}>
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              Amount
            )}
          </div>
          <div className={`text ${Theme}_regular font14 color_deep-ocean`}>
            Due Amount
          </div>
        </div>
        <div
          className={`button color_white ${Theme}_semibold font16`}
          onClick={() =>
            payNowLoading === true || continueButtonError == true
              ? null
              : submitForm()
          }
        >
          Pay Now{" "}
          {(payNowLoading == true || continueButtonError == true) && (
            <div className={`loader ${Theme}`}></div>
          )}
        </div>
      </div>

      {/* Policy Row */}
      <div className={`policy-row`}>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          By continuing to pay , I understand and I agree with the{" "}
        </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          privacy policy
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}> and </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          terms of service
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          {" "}
          of STFares
        </div>
      </div>
    </div>
  );
}

export default EMI;
