import React, { useEffect, useState, useMemo, useCallback } from "react";
import "./index.css";
import FlightDetailsIndicator from "../../Components/FlightDetailsIndicator/FlightDetailsIndicator";
import FligthDetails from "../../Components/FligthDetails";
import {
  formatTime,
  getTotalMinutes,
} from "../../../../../../Utils/Date_Time_Calendar_Functions";
import TagsContainer from "../../Components/TagsContainer";
import MainPaymentMethods from "../../../../../Global/PaymentMethods/MainPaymentMethods";

function RoundwayINTCombine({ Theme, Data , Amount , TravellerData}) {
 
  return (
    <>
      <div className="main-section-wrapper">

        <div className="main-data-section">
          <div
            className={`flight-details-1 ${Theme}_semibold color_${
              Theme == "proxima" ? "magic-potion" : "optera-galaxy"
            } font16`}
          >
            FLIGHT DETAILS
          </div>

          <FlightDetailsIndicator
            Theme={Theme}
            Type={1}
            isReturn={true}
            isTrip={false}
            TripName={"ONWARDS"}
            Refundability={Data[0].IsRefundable == true
                      ? "Refundable"
                      : "Non-Refundable"
            }
            From={
                    Data[0].Segments[0][0].Origin.Airport
                      .CityName
            }
            To={
                    Data[0].Segments[0][
                      Data[0].Segments[0].length - 1
                    ].Destination.Airport.CityName
                   
            }
            Stops={
                    Data[0].Segments[0].length === 1
                      ? "Non Stop"
                      : Data[0].Segments[0].length -
                        1 +
                        " Stops"
            }
            Time={formatTime(
                    getTotalMinutes(
                      Data[0].Segments[0][0].Origin
                        .DepTime,
                      Data[0].Segments[0][
                        Data[0].Segments[0].length - 1
                      ].Destination.ArrTime
                    )
                  )
            }
            FFName={Data[0].ChoosedFamily
                    
            }
          />

          <div className={`FD-wrapper ${Theme}`}>
            {(Data[0].Segments[0].length == 1) && (
              <FligthDetails
                Theme={Theme}
                data={Data[0].Segments[0][0]
                }
                TravellersData={TravellerData}
                AncellaryIndex={[0,0]}
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={Data[0].Segments[0]}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

            {(Data[0].Segments[0].length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data[0].Segments[0][0]}
                  TravellersData={TravellerData}
                  AncellaryIndex={[0,0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[0]}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  TravellersData={TravellerData}
                  AncellaryIndex={[0,1]}
                  data={
                    Data !== undefined
                      ? Data[0].Segments[0][1]
                      : Data[0].Segments[0][1]
                  }
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[0]}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

            {(Data[0].Segments[0].length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  TravellersData={TravellerData}
                  AncellaryIndex={[0,0]}
                  data={Data[0].Segments[0][0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[0]}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  TravellersData={TravellerData}
                  AncellaryIndex={[0,1]}
                  data={Data[0].Segments[0][1]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[0]}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  TravellersData={TravellerData}
                  AncellaryIndex={[0,2]}
                  data={Data[0].Segments[0][2]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[0]
                         }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          </div>

          <FlightDetailsIndicator
            Theme={Theme}
            Type={1}
            Data={Data}
            isReturn={true}
            isTrip={false}
            TripName={"RETURN"}
            Refundability={Data[0].IsRefundable == true
                      ? "Refundable"
                      : "Non-Refundable"
            }
            From={
                    Data[0].Segments[1][0].Origin.Airport
                      .CityName
            }
            To={
                    Data[0].Segments[1][
                      Data[0].Segments[1].length - 1
                    ].Destination.Airport.CityName
                   
            }
            Stops={
                    Data[0].Segments[1].length === 1
                      ? "Non Stop"
                      : Data[0].Segments[1].length -
                        1 +
                        " Stops"
            }
            Time={formatTime(
                    getTotalMinutes(
                      Data[0].Segments[1][0].Origin
                        .DepTime,
                      Data[0].Segments[1][
                        Data[0].Segments[1].length - 1
                      ].Destination.ArrTime
                    )
                  )
            }
            FFName={Data[0].ChoosedFamily
                    
            }
          />

          <div className={`FD-wrapper ${Theme}`}>
            {(Data[0].Segments[1].length == 1) && (
              <FligthDetails
              TravellersData={TravellerData}
              AncellaryIndex={[1,0]}
                Theme={Theme}
                data={Data[0].Segments[1][0]
                }
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={Data[0].Segments[1]}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

            {(Data[0].Segments[1].length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data[0].Segments[1][0]}
                  TravellersData={TravellerData}
                  AncellaryIndex={[1,0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[1]}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  TravellersData={TravellerData}
                  AncellaryIndex={[1,1]}
                  data={
                    Data == undefined
                      ? Data[0].Segments[1][1]
                      : Data[0].Segments[1][1]
                  }
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[1]}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

            {(Data[0].Segments[1].length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  TravellersData={TravellerData}
                  AncellaryIndex={[1,0]}
                  data={Data[0].Segments[1][0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[1]}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  TravellersData={TravellerData}
                  AncellaryIndex={[1,1]}
                  data={Data[0].Segments[1][1]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[1]}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  TravellersData={TravellerData}
                  AncellaryIndex={[1,2]}
                  data={Data[0].Segments[1][2]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data[0].Segments[1]
                         }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          </div>


         
        </div>

     {/* // payment methods and gateway */}
     <MainPaymentMethods Theme={Theme} Amount={Amount}/>
      </div>

    </>
  );
}

export default RoundwayINTCombine;
