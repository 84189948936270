import React, { useEffect, useState } from "react";
import Header from "../../../Components/Header";
import "./FlightPayments.css";
import Timer from "../components/Timer/Timer";
import { useDispatch, useSelector } from "react-redux";
import { getTheResultType } from "../../../Utils/FlightFunc";

import OnewayData from "./Components/OnewayData";
import RoundwayDom from "./Components/RoundwayData_Domestic";
import RoundwayINTSplit from "./Components/Round_International/Split";
import RoundwayINTCombine from "./Components/Round_International/Combined";
import MultiwayDom2 from "./Components/MultiDomestic/2Row";
import MultiwayDom3 from "./Components/MultiDomestic/3Row";
import MultiwayDom4 from "./Components/MultiDomestic/4Row";
import MultiwayINTSplit2 from "./Components/MultiInternational/2Row/Split";
import MultiwayINTCombine2 from "./Components/MultiInternational/2Row/Combined";
import MultiwayINTSplit3 from "./Components/MultiInternational/3Row/Split";
import MultiwayINTCombine3 from "./Components/MultiInternational/3Row/Combined";
import MultiwayINTSplit4 from "./Components/MultiInternational/4Row/Split";
import MultiwayINTCombine4 from "./Components/MultiInternational/4Row/Combined";
import FareDetailsSB from "./Components/FareDetailsSB/FareDetailsSB";
import {
  fetch_Payment,
  setLoading,
  setOpenPopupData,
  setOpenPopupMini,
  setOpenPopupMiniType,
} from "../../../Redux/Slices/Main_HomePage_Slice/HomePage_SLICE";
import { MAIN_STFARES_URL } from "../../../Config/Api";
import { useNavigate } from "react-router-dom";

function FlightPayments({ Theme }) {
  let dispatch = useDispatch();

  let {
    PaymentData,
    BookingData,
    AncellariesPrice,
    isPriceChanged,
    PriceChangedData,
    OpenPopupMini ,
    OpenPopupData , 
    OpenPopupMiniType,
    payment_Status,
  } = useSelector((state) => state.HomePageMain);

  let navigate = useNavigate("");

  let [resultType, setResultType] = useState(1);

  useEffect(() => {
    let getType = getTheResultType(BookingData.name);

    setResultType(getType);
  }, [BookingData]);

  const handleContinue = (callpayment) => {
    dispatch(setOpenPopupMini(false));
    dispatch(setOpenPopupMiniType(""));
    dispatch(setOpenPopupData({}));

    if (callpayment == true) {
      dispatch(setLoading(true));
      dispatch(fetch_Payment()); /// to fetch the latest and new flight details
    }
  };

  const handleGoBack = () => {
    window.location.replace(`${MAIN_STFARES_URL}`);
  };

    return (
      <>
        <Header />
        <div className={`flight-payments ${Theme}`}>
          <Timer Theme={Theme} />
  
          <div className={`main-bar-section ${Theme}`}>
            <div className="detail-section">
              <>
                {(resultType === 1 || resultType === 2) && (
                  <OnewayData
                    Theme={Theme}
                    Data={BookingData.flight_data}
                    TravellerData={BookingData.traveller_data.registered}
                    Amount={PaymentData.amount}
                  />
                )}
                {resultType === 3 && (
                  <RoundwayDom
                    Theme={Theme}
                    Data={BookingData.flight_data}
                    TravellerData={BookingData.traveller_data.registered}
                    Amount={PaymentData.amount}
                  />
                )}
                {resultType === 4 &&
                  (BookingData.flight_data[0] == undefined ? (
                    <RoundwayINTSplit
                      Theme={Theme}
                      Data={BookingData.flight_data}
                      TravellerData={BookingData.traveller_data.registered}
                      Amount={PaymentData.amount}
                    />
                  ) : (
                    <RoundwayINTCombine
                      Theme={Theme}
                      Data={BookingData.flight_data}
                      TravellerData={BookingData.traveller_data.registered}
                      Amount={PaymentData.amount}
                    />
                  ))}
                {resultType === 5 && (
                  <MultiwayDom2
                    Theme={Theme}
                    Data={BookingData.flight_data}
                    TravellerData={BookingData.traveller_data.registered}
                    Amount={PaymentData.amount}
                  />
                )}
                {resultType === 7 && (
                  <MultiwayDom3
                    Theme={Theme}
                    Data={BookingData.flight_data}
                    TravellerData={BookingData.traveller_data.registered}
                    Amount={PaymentData.amount}
                  />
                )}
                {resultType === 9 && (
                  <MultiwayDom4
                    Theme={Theme}
                    Data={BookingData.flight_data}
                    TravellerData={BookingData.traveller_data.registered}
                    Amount={PaymentData.amount}
                  />
                )}
                {resultType === 6 &&
                  (BookingData.flight_data[0] == undefined ? (
                    <MultiwayINTSplit2
                      Theme={Theme}
                      Data={BookingData.flight_data}
                      TravellerData={BookingData.traveller_data.registered}
                      Amount={PaymentData.amount}
                    />
                  ) : (
                    <MultiwayINTCombine2
                      Theme={Theme}
                      Data={BookingData.flight_data}
                      TravellerData={BookingData.traveller_data.registered}
                      Amount={PaymentData.amount}
                    />
                  ))}
                {resultType === 8 &&
                  (BookingData.flight_data[0] == undefined ? (
                    <MultiwayINTSplit3
                      Theme={Theme}
                      Data={BookingData.flight_data}
                      TravellerData={BookingData.traveller_data.registered}
                      Amount={PaymentData.amount}
                    />
                  ) : (
                    <MultiwayINTCombine3
                      Theme={Theme}
                      Data={BookingData.flight_data}
                      TravellerData={BookingData.traveller_data.registered}
                      Amount={PaymentData.amount}
                    />
                  ))}
                {resultType === 10 &&
                  (BookingData.flight_data[0] == undefined ? (
                    <MultiwayINTSplit4
                      Theme={Theme}
                      Data={BookingData.flight_data}
                      TravellerData={BookingData.traveller_data.registered}
                      Amount={PaymentData.amount}
                    />
                  ) : (
                    <MultiwayINTCombine4
                      Theme={Theme}
                      Data={BookingData.flight_data}
                      TravellerData={BookingData.traveller_data.registered}
                      Amount={PaymentData.amount}
                    />
                  ))}
              </>
  
              <div className="fare-summary-section">
                <FareDetailsSB
                  Theme={Theme}
                  ResultType={resultType}
                  Data={BookingData.flight_data}
                  CouponData={
                    BookingData.coupons_and_discounts_used === null
                      ? { Code: "" }
                      : BookingData.coupons_and_discounts_used
                  }
                  AncellariesPrice={AncellariesPrice}
                  isPriceChanged={isPriceChanged}
                  Message={PriceChangedData.Message}
                />
              </div>
            </div>
          </div>
  
          {OpenPopupMini && (
            <div className={`mini-popup-overlay-Review ${Theme}`}>
              <div className="popup-content">
                {OpenPopupMiniType == "Price_Changed" && (
                  <>
                    <p>{OpenPopupData?.Message}</p>
                    <div className="popup-buttons">
                      <button
                        onClick={() =>
                          handleContinue(
                            OpenPopupData?.fetchFlight
                          )
                        }
                        className="popup-button continue-button"
                      >
                        Continue
                      </button>
                      <button
                        onClick={() => handleGoBack()}
                        className="popup-button go-back-button"
                      >
                        Go TO HomePage
                      </button>
                    </div>
                  </>
                )}
                {OpenPopupMiniType == "Loading" && (
                  <p>Loading .. Please Wait</p>
                )}
                {OpenPopupMiniType == "Message" && (
                  <>
                    <h3>{OpenPopupData?.Heading}</h3>
  
                    <p>{OpenPopupData?.Data}</p>
                  </>
                )}
                {OpenPopupMiniType == "Error" && (
                  <>
                    <h2>{OpenPopupData?.Heading}</h2>
                    {!Array.isArray(OpenPopupData?.Data) ? (
                      <p>{OpenPopupData?.Data}</p>
                    ) : (
                      <div>
                        {OpenPopupData?.Data.map((item, id) => {
                          return <div>{item.Message}</div>;
                        })}
                      </div>
                    )}
                    <div className="popup-buttons">
                      {OpenPopupData?.showContinue == true && (
                        <button
                          onClick={() => handleContinue(
                                  OpenPopupData?.fetchFlight
                                )
                          }
                          className="popup-button continue-button"
                        >
                         Continue
                           
                        </button>
                      )}
                      <button
                        onClick={() => handleGoBack()}
                        className="popup-button go-back-button"
                      >Go back
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
 
}

export default FlightPayments;
