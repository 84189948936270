import React, { useState } from "react";
import "./fligthdetails.css";
import AirlinesLogContainer from "./AirlinesLogContainer";
import images from "../../../../../Utils/importImages";
import {
  formatDateResultBar,
  formatTime,
  getTotalMinutes,
} from "../../../../../Utils/Date_Time_Calendar_Functions";

function FligthDetails({ data, Tags, Theme, TravellersData, AncellaryIndex }) {
  let [showBagDetails, setshowBagDetails] = useState(false);

  let getCheckinBag = (paxtype, selectedBag) => {
    if (paxtype === "ADT") {
      if (selectedBag == null || selectedBag == "") {
        return `${data.Baggage.Adults[0].CheckinBag}`;
      } else {
        return `${data.Baggage.Adults[0].CheckinBag} + ${selectedBag.Weight} ${selectedBag.Weight_type}`;
      }
    } else if (paxtype === "CHD") {
      if (selectedBag == null || selectedBag == "") {
        return `${data.Baggage.Childs[0].CheckinBag}`;
      } else {
        return `${data.Baggage.Childs[0].CheckinBag} + ${selectedBag.Weight} ${selectedBag.Weight_type}`;
      }
    } else {
      if (selectedBag == null || selectedBag == "") {
        return `${data.Baggage.Infrants[0].CheckinBag}`;
      } else {
        return `${data.Baggage.Infrants[0].CheckinBag} + ${selectedBag.Weight} ${selectedBag.Weight_type}`;
      }
    }
  };
  return (
    <div className="flight-detail-content">
      <div className="flight-content">
        <div className="frame-242-1">
          <div className="frame-238">
            <div className="frame-238-1">
              <div className="frame-104">
                <div className="frame-103">
                  <AirlinesLogContainer
                    allAirlines={[
                      {
                        name: data.Airline.AirlineName,
                        code: data.Airline.AirlineCode,
                        logo: data.Airline.AirlineLogo,
                        Number: data.Airline.FlightNumber,
                      },
                    ]}
                  />
                  <div className="frame-168">
                    <div className="frame-245">
                      <div className="frame">
                        <div
                          className={`air-india ${Theme}_medium color_eerie-black font16`}
                        >
                          {data.Airline.AirlineName}
                        </div>
                      </div>
                      <div className="frame-1">
                        <div
                          className={`ai-123 ${Theme}_light color_eerie-black font13`}
                        >
                          {data.Airline.AirlineCode} {data.Airline.FlightNumber}
                        </div>
                        <div className="frame-245-1">
                          <div className="frame-176"></div>
                          <div className="frame">
                            <div
                              className={`airbus-a321 ${Theme}_regular color_eerie-black font13`}
                            >
                              {data.ItenaryDetails.Craft}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-174-1">
                        <div
                          className={`operated-by-air-canada-4 ${Theme}_regular color_eerie-black font13`}
                        >
                          {(data.Airline.AirlineCode ==
                            data.Airline.OperatingCarrier) ==
                          true
                            ? "Operated By " + data.Airline.AirlineName
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-237">
                <div className="frame-242-2">
                  <div className="frame-2-2 frame-2-10">
                    <div className="frame-2-2 frame-2-10">
                      <div
                        className={`text-311 text ${Theme}_semibold color_eerie-black font20`}
                      >
                        {data.Origin.DepTime.substring(11, 16)}
                      </div>
                    </div>
                    <div
                      className={`tue-30-jan-2024-8 valign-text-middle ${Theme}_regular color_light-gray-black font13`}
                    >
                      {formatDateResultBar(data.Origin.DepTime)}
                    </div>
                  </div>
                  <div
                    className={`frame-2-3 frame-2-10 ${Theme}_regular color_gravel font15`}
                  >
                    <div className="del-4 color_deep-ocean">
                      {data.Origin.Airport.AirportCode},
                    </div>
                    <div className="new-delhi-india-4">
                      {data.Origin.Airport.CityName},{" "}
                      {data.Origin.Airport.CountryName}
                    </div>
                  </div>
                  <div
                    className={`indira-gandhi-international-terminal-2-8 ${Theme}_regular color_gravel font14`}
                  >
                    {data.Origin.Airport.AirportName}
                  </div>

                  {data.Origin.Airport.Terminal !== "" &&
                    data.Origin.Airport.Terminal !== null && (
                      <div
                        className={`indira-gandhi-international-terminal-2-8 ${Theme}_regular color_gravel font14`}
                      >
                        {"Terminal " + data.Origin.Airport.Terminal}
                      </div>
                    )}
                </div>
                <div className="frame-237-1">
                  <div className="frame-170">
                    <div className="frame-202">
                      <div className="line-through"></div>

                      <img
                        src={images["clock-sign-black.svg"]}
                        alt="Rectangle 72"
                      />
                      <div
                        className={`x2h-40m ${Theme}_regular color_eerie-black font13`}
                      >
                        {formatTime(
                          getTotalMinutes(
                            data.Origin.DepTime,
                            data.Destination.ArrTime
                          )
                        )}
                      </div>
                      <div className="line-through"></div>
                    </div>
                  </div>
                </div>
                <div className="frame-243">
                  <div className="frame-2-2 frame-2-10">
                    <div className="frame-2-2 frame-2-10">
                      <div
                        className={`text-312 text ${Theme}_semibold color_eerie-black font20`}
                      >
                        {data.Destination.ArrTime.substring(11, 16)}
                      </div>
                    </div>
                    <div
                      className={`tue-30-jan-2024-8 valign-text-middle ${Theme}_regular color_light-gray-black font13`}
                    >
                      {formatDateResultBar(data.Destination.ArrTime)}
                    </div>
                  </div>
                  <div
                    className={`frame-2-3 frame-2-10 ${Theme}_regular color_gravel font15`}
                  >
                    <div className="tvr-4 color_deep-ocean">
                      {data.Destination.Airport.AirportCode},
                    </div>
                    <div className="thiruvanathapuram-india-4">
                      {data.Destination.Airport.CityName},{" "}
                      {data.Destination.Airport.CountryName}
                    </div>
                  </div>
                  <div
                    className={`indira-gandhi-international-terminal-2-8 ${Theme}_regular color_gravel font14`}
                  >
                    {data.Destination.Airport.AirportName}
                  </div>
                  {data.Destination.Airport.Terminal !== "" &&
                    data.Destination.Airport.Terminal !== null && (
                      <div
                        className={`indira-gandhi-international-terminal-2-8 ${Theme}_regular color_gravel font14`}
                        style={{ textAlign: "end" }}
                      >
                        {"Terminal " + data.Destination.Airport.Terminal}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="travellers-data-wrapper">
      <div className="travellers-row-heading">
        <div className={`${Theme}_semibold font14 color_deep-ocean`}>TRAVELLERS</div>
        <div className={`${Theme}_semibold font14 color_deep-ocean`}>CHECK-IN</div>
        <div className={`${Theme}_semibold font14 color_deep-ocean`}>CABIN</div>
        <div className={`${Theme}_semibold font14 color_deep-ocean`}>SEAT</div>
        <div className={`${Theme}_semibold font14 color_deep-ocean`}>MEAL</div>
      </div>
      <div className="travellers-row-content">
        <div className={`${Theme}_regular font14 color_deep-ocean`}>Prince Sharma</div>
        <div className={`${Theme}_regular font14 color_deep-ocean`}>25kg</div>
        <div className={`${Theme}_regular font14 color_deep-ocean`}>5kg</div>
        <div className={`${Theme}_regular font14 color_deep-ocean`}>3A Window</div>
        <div className={`${Theme}_regular font14 color_deep-ocean`}>Salad</div>
      </div>
      <div className="travellers-row-content">
        <div className={`${Theme}_regular font14 color_deep-ocean`}>Prince Sharma</div>
        <div className={`${Theme}_regular font14 color_deep-ocean`}>25kg</div>
        <div className={`${Theme}_regular font14 color_deep-ocean`}>5kg</div>
        <div className={`${Theme}_regular font14 color_deep-ocean`}>3A Window</div>
        <div className={`${Theme}_regular font14 color_deep-ocean`}>Salad</div>
      </div>

      <div>

      </div>

      </div> */}

        <div className="thin-line"></div>
        <table className="travellers-data-wrapper">
          <thead>
            <tr className="travellers-row-heading">
              <th className={`${Theme}_semibold font14 color_deep-ocean`}>
                TRAVELLERS
              </th>
              <th className={`${Theme}_semibold font14 color_deep-ocean`}>
                CHECK-IN
              </th>
              <th className={`${Theme}_semibold font14 color_deep-ocean`}>
                CABIN
              </th>
              <th className={`${Theme}_semibold font14 color_deep-ocean`}>
                SEAT
              </th>
              <th className={`${Theme}_semibold font14 color_deep-ocean`}>
                MEAL
              </th>
            </tr>
          </thead>
          <tbody>
            {TravellersData.map((item, id) => {
              let name = `${item.title} ${item.firstName} ${item.lastName} (${
                item.type === "ADT"
                  ? "Adult"
                  : item.type === "CHD"
                  ? "Child"
                  : "Infant"
              })`;

              let chekinBag = getCheckinBag(
                item.type,
                item.ancellaries.bags[AncellaryIndex[0]][0].bagRequested
              );
              let cabinBag =
                item.type == "ADT"
                  ? data.Baggage.Adults[0].CabinBaggage
                  : item.type == "CHD"
                  ? data.Baggage.Childs[0].CabinBaggage
                  : data.Baggage.Infrants[0].CheckinBag;

              let seat =
                item.ancellaries.seats[AncellaryIndex[0]][AncellaryIndex[1]]
                  .seatRequested == null ||
                item.ancellaries.seats[AncellaryIndex[0]][AncellaryIndex[1]]
                  .seatRequested == ""
                  ? "---"
                  : `${
                      item.ancellaries.seats[AncellaryIndex[0]][
                        AncellaryIndex[1]
                      ].seatRequested.Code
                    } ${
                      item.ancellaries.seats[AncellaryIndex[0]][
                        AncellaryIndex[1]
                      ].seatRequested.SeatPosition
                    }`;

              let meal =
                item.ancellaries.meals[AncellaryIndex[0]][AncellaryIndex[1]]
                  .mealRequested == null ||
                item.ancellaries.meals[AncellaryIndex[0]][AncellaryIndex[1]]
                  .mealRequested == ""
                  ? "---"
                  : `${
                      item.ancellaries.meals[AncellaryIndex[0]][
                        AncellaryIndex[1]
                      ].mealRequested.Name
                    }`;

              return (
                <tr className="travellers-row-content">
                  <td className={`${Theme}_regular font14 color_deep-ocean`}>
                    {name}
                  </td>
                  <td className={`${Theme}_regular font14 color_deep-ocean`}>
                    {chekinBag}
                  </td>
                  <td className={`${Theme}_regular font14 color_deep-ocean`}>
                    {cabinBag}
                  </td>
                  <td className={`${Theme}_regular font14 color_deep-ocean`}>
                    {seat}
                  </td>
                  <td className={`${Theme}_regular font14 color_deep-ocean`}>
                    {meal}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {Tags}
    </div>
  );
}

export default FligthDetails;
