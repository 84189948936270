import React, { useState, useEffect, useRef } from "react";
import "./date_Selector.css";
import images from "../../../../../Utils/importImages";

function MonthSelector({
  Theme,
  Heading,
  onBlur,
  error,
  helperText,
  value,
  onChange,
  DropDownYears,
  clickedInside,
  setClickedInside,
  SetCallValidator
}) {


  const [isActive, setIsActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(value);

  const ref = useRef(null);

  const handleClickInside = (dropdown) => {
    setIsActive(true);
    setClickedInside(true);
    setActiveDropdown(dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        SetCallValidator(true);
        setIsActive(false);
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clickedInside]);

  // Automatically open the year dropdown after selecting a month

  const handleSelectMonth = (month) => {
    setSelectedMonth(month.val);
  };
  
  useEffect(() => {

    if (selectedMonth && isActive == true && activeDropdown === "month") {
      setActiveDropdown(null);
    }
    
  } , [selectedMonth]);

  useEffect(() => {
    if (clickedInside === true) {
      onChange(selectedMonth);
    }
  }, [selectedMonth]);

  let getMonthName = (key) => {
    switch (key) {
      case '01':
        return "January";
      case '02':
        return "February";
      case '03':
        return "March";
      case '04':
        return "April";
      case '05':
        return "May";
      case '06':
        return "June";
      case '07':
        return "July";
      case '08':
        return "August";
      case '09':
        return "September";
      case '10':
        return "October";
      case '11':
        return "November";
      case '12':
        return "December";
      default :
         return "";   
  }
  }
  return (
    <div className="dateselector-wrapper" style={{width:"100%"}}>
      <div
        ref={ref}
        className={`dateselector ${isActive ? "active" : ""} ${error && "error"
          } ${Theme}`}
        onClick={() => setIsActive(true)}
      >
        <div className={`${Theme}_regular font13 headbar ${Theme} ${error && "error"}`}>
          {Heading}
        </div>

        <div className="databar">

          {/* Month Section */}
          <div onClick={() => handleClickInside("month")} className="section">
            <div className={`${Theme}_regular color_light-cement font16 d-head`}>
              {getMonthName(selectedMonth) || "Month"}
            </div>
            {activeDropdown === "month" && (
              <div className="dropdown dropdown-month">
                {[
                  {name : "January" ,  val : '01'},
                  {name : "February" ,  val : '02'},
                  {name : "March" ,  val : '03'},
                  {name : "April" ,  val : '04'},
                  {name : "May" ,  val : '05'},
                  {name : "June" ,  val : '06'},
                  {name : "July" ,  val : '07'},
                  {name : "August" ,  val : '08'},
                  {name : "September" ,  val : '09'},
                  {name : "October" ,  val : '10'},
                  {name : "November" ,  val : '11'},
                  {name : "December" ,  val : '12'},
                 
                ].map((month) => (
                  <div
                    key={month}
                    onClick={() => handleSelectMonth(month)}
                    className={`${Theme}_${selectedMonth == month.val ? "semibold" : "regular"
                      } color_light-cement font16 dropdown-item`}
                  >
                    {month.name}
                  </div>
                ))}
              </div>
            )}
            <img
              src={images[`dmy-arrow-${Theme === "proxima" ? "blue" : "gold"}.svg`]}
              alt="arrow"
              className={`dd-arrow ${activeDropdown === "month" && "open"}`}
            />
          </div>

        </div>
      </div>

      {error && (
        <div className={`${Theme}_regular color_error-red errorbar`}>
          {helperText}
        </div>
      )}
    </div>
  );
}

export default MonthSelector;
