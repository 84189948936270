const formatDate = (date) => {

if(date === null){
  return null;
}
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month?.length < 2) month = "0" + month;
  if (day?.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const getFirstTwoLettersOfDate = (date) => {
  // Assuming the date is in the format "DD-MM-YYYY"
   // Extract the first two characters

  return date.slice(-2);
};

const getThreeLetterMonth = (date) => {
  // Assuming the date is in the format "DD-MM-YYYY"

  // console.log("thee letetr mibtg us  L : " , date);
  const monthNumber = date.slice(5, 7); // Extract the month part
  console.log("montnumber is  : " , monthNumber);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[parseInt(monthNumber, 10) - 1]; // Convert to integer and get the corresponding month name
};

const getTwoLetterYear = (date) => {
  // Assuming the date is in the format "DD-MM-YYYY"
  return date.slice(2, 4); // Extract the last two characters
};

const getDayOfTheDate = (date) => {
    // Assuming the date is in the format "DD-MM-YYYY"
    const [day, month, year] = date.split('-');
    
    // JavaScript Date object month is 0-based, so we need to subtract 1 from the month
    const dateObject = new Date(year, month - 1, day);
    
    // Array of week day names
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    
    // Get the day of the week as a number (0-6)
    const dayOfWeekNumber = dateObject.getDay();
    
    // Return the name of the day
    return daysOfWeek[dayOfWeekNumber];
  };

  const getCalendarDateFormatted = (date) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    return `${day} ${month}, ${year}`;
  };

  const getNoOfDays = (date1, date2) => {
    // Convert both dates to milliseconds
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  
    // Convert the time difference from milliseconds to days
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  
    return daysDiff;
  };

  const formatDateToSeatchParams = (inputDate) => {
    const parts = inputDate.split('-'); // Split the input date string by hyphens
    return`${parts[2]}-${parts[1]}-${parts[0]}`; // Rearrange the parts
}

  const formatDateForCalendarBar = (inputDate) => {
    const parts = inputDate.split('-'); // Split the input date string by hyphens
    return`${parts[1]}-${parts[0]}-${parts[2]}`; // Rearrange the parts
}

const formatDateRecentSearch = (dateString) => {
  // Parse the input date string
  const date = new Date(dateString);

  // Define options for Intl.DateTimeFormat
  const options = { weekday: 'short', day: 'numeric', month: 'short', year: '2-digit' };

  // Format the date using Intl.DateTimeFormat
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(date);

  return formattedDate;
}

function convertDateStringResultPage(dateString) {

  if(dateString === null){
    return "Invalid Date";
  }
  // Create a Date object from the input date string
  const date = new Date(dateString);

  // Ensure the date is valid
  if (isNaN(date)) {
    throw new Error('Invalid date format. Expected format: YYYY-MM-DD');
  }

  // Format the date parts
  const options = { weekday: 'short', day: '2-digit', month: 'short', year: '2-digit' };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const [{ value: weekday },,{ value: day },,{ value: month },,{ value: year }] = formatter.formatToParts(date);

  return `${weekday}, ${day} ${month}, ${year}`;
}


export const generateDays = (month_year) => {

  // console.log("month_year is : " , month_year);
  const [month, year] = month_year.split(" ");
  const days = [];
  const date = new Date(`${month} 1, ${year}`);
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const numberOfDaysInMonth = lastDayOfMonth.getDate();

  // Fill in the days before the first day of the month
  for (let i = 0; i < firstDayOfMonth.getDay(); i++) {
    days.push([null, null]);
  }

  // Fill in the days of the month
  for (let i = 1; i <= numberOfDaysInMonth; i++) {
    const currentDate = new Date(`${month} ${i}, ${year}`);

    let date = currentDate.getDate();
    let dateconverted = date < 10 ? "0"+date : date;

    let monthh = currentDate.getMonth() + 1;
    let monthConverted = monthh < 10 ? "0"+monthh : monthh;

    const formattedDate = `${dateconverted}-${monthConverted}-${currentDate.getFullYear()}`;
    days.push([i, formattedDate , currentDate]);
  }

  // Fill in the days after the last day of the month
  const remainingDays = 7 - (days?.length % 7);
  // console.log("remainingDays are : " , remainingDays);
  if(remainingDays != 7){
    for (let i = 0; i < remainingDays; i++) {
      days.push([null, null]);
    }

  }

  // console.log("the dyas are  : " , days);
  return days;
};


export const getdateformattedrange = (date) => {
  const day = ("0" + date.getDate()).slice(-2); // Extract day and pad with leading zero if needed
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Extract month (months are zero-based) and pad with leading zero if needed
  const year = date.getFullYear(); // Extract year

  return `${day}-${month}-${year}`; // Concatenate the components in the desired format
}


export const generateDates = (numberOfDays) => {
  const dates = [];
  const today = new Date();

  for (let i = 0; i < numberOfDays; i++) {
    const newDate = new Date(today);
    newDate.setDate(today.getDate() + i);

    const day = newDate.toLocaleString('en-US', { weekday: 'short' });
    const dayDate = `${newDate.getDate().toString().padStart(2, '0')}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${newDate.getFullYear()}`;

    dates.push([`${day}, ${newDate.getDate()} ${newDate.toLocaleString('en-US', { month: 'short' })}` , `${dayDate}`]);
  }

  return dates;
};

export const formatTime = (minutes) => {
  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;
  if (hrs < 1) {
    return mins ? `${mins}min` : '';
  }
  return mins > 0 ? `${hrs}h ${mins}min` : `${hrs}h`;
};


export function getTotalMinutes(startTime, endTime) {
  // Convert the time strings to Date objects
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  // Calculate the difference in milliseconds
  const diffInMilliseconds = endDate - startDate;

  // Convert the difference from milliseconds to minutes
  const diffInMinutes = diffInMilliseconds / (1000 * 60);

  return diffInMinutes;
}

export function getTotalDays(departureTime, arrivalTime) {
  const depTime = new Date(departureTime);
  const arrTime = new Date(arrivalTime);
  const timeDifference = arrTime - depTime;
  const totalDays = timeDifference / (1000 * 60 * 60 * 24); // Convert milliseconds to days

  let days = Math.floor(totalDays);

  if (days > 0) {
    return `+${days} ${days > 1 ? " Days" : " Day"}`;
  }
}


export function formatDateResultBar(dateString) {
  const date = new Date(dateString);

  const options = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);

  // Customize the format to 'Tue, 30 Jan 24'
  const [weekday, day, month, year] = formattedDate.split(" ");
  return `${weekday} ${day} ${month} ${year}`;
}

export function formatTimeRangeAMPM(startTime, endTime) {
  const formatTime = (timeString) => {
    const date = new Date(timeString);
    let hours = date.getHours();
    const minutes = ("0" + date.getMinutes()).slice(-2); // Ensure minutes are two digits

    const period = hours >= 12 ? "Pm" : "Am";
    hours = hours % 12 || 12; // Convert hours to 12-hour format
    const formattedTime = `${hours}:${minutes} ${period}`;

    return formattedTime;
  };

  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);

  return `${formattedStartTime} - ${formattedEndTime}`;
}


export {
  formatDateForCalendarBar,
  formatDate,
  getFirstTwoLettersOfDate,
  getThreeLetterMonth,
  getTwoLetterYear,
  getDayOfTheDate,
  getCalendarDateFormatted,
  getNoOfDays,
  formatDateToSeatchParams,
  formatDateRecentSearch,
  convertDateStringResultPage
};
