import React, { useState } from "react";
import "./UPI.css";
import EditBar from "./Components/EditBar";
import images from "../../../../Utils/importImages";
import { TextField } from "@mui/material";

function UPI({ Theme, Amount, paymentOptionsAvailable }) {
  // console.log("paymentOptionsAvailable is : ", paymentOptionsAvailable);

  let [geenrateQr  ,  setGenerateQr] = useState(false)

  let [upiId, setUpiId] = useState("");
  let [upiIdError, setUpiIdError] = useState("");

  let onChangeUPIId = (info) => {
    setUpiIdError("");
    setUpiId(info.target.value);
  };

  const validateUPIId = () => {
    if (/^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/.test(upiId)) {
      setUpiIdError("Invalid Upi Id");
    }
  };

  return (
    <div className="Upi-container">
      <EditBar
        Theme={Theme}
        Heading={"SCAN AND PAY"}
        showEdit={false}
        onClickEdit={null}
      />
      <div className="qr-container">
        <div className="qr-generator">
          <div className="generator-info">
            <img className="blur-qr" src={images[geenrateQr == false ? "qr-code-img-blur.jpg" : "qr-code-img.svg"]} alt="" />

{
  geenrateQr == false && 
            <div className={`qr-generator-btn ${Theme}_semibold font14`} onClick={() => setGenerateQr(true)}>
              GENERATE QR
            </div>
}
          </div>
          <div className={`${Theme}_regular font14 color_deep-ocean`}>
            Scan and pay using any banking app.
          </div>
        </div>
        <div className="qr-info">
          {
            geenrateQr == true && 
            <>
          <div className={`timer ${Theme}_semibold font14 color_deep-ocean`}>
            <img src={images["payment-clock-icon.svg"]} alt="" />
            8m 56s
          </div>

          <div className={`timer-info ${Theme}_regular font13 color_gravel`}>
            Please approve the transaction within given time. We will confirm
            your booking once payment is successfull.
          </div>
            
            </>
          }
        </div>
      </div>

      <div className="or-divider">
        <div className="divider-row"></div>
        <div className={`or ${Theme}_regular font18 color_deep-ocean`}>or</div>
        <div className="divider-row"></div>
      </div>

      <EditBar
        Theme={Theme}
        Heading={"ENTER YOUR UPI ID"}
        showEdit={false}
        onClickEdit={null}
      />

      <TextField
        autoComplete="off"
        onBlur={validateUPIId}
        error={!!upiIdError}
        helperText={upiIdError}
        value={upiId}
        onChange={onChangeUPIId} // Handle change
        id="outlined-basic"
        label="Your Upi Id"
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme === "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontWeight: "400",
            "&.Mui-focused": {
              color: Theme === "averta" ? "#C2A07C" : "#0057B7", // Color when focused
            },
          },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
            },
          },
        }}
      />

      <div className="upi-instructions-row">
        <div
          className={`upi-instructions-row-item ${Theme}_regular font14 color_deep-ocean`}
        >
          <img src={images["list-icon.svg"]} alt="" />
          Enter your registered VPA.
        </div>
        <div
          className={`upi-instructions-row-item ${Theme}_regular font14 color_deep-ocean`}
        >
          <img src={images["list-icon.svg"]} alt="" />
          Receive payment request on bank app
        </div>
        <div
          className={`upi-instructions-row-item ${Theme}_regular font14 color_deep-ocean`}
        >
          <img src={images["list-icon.svg"]} alt="" />
          Authorize payment request.
        </div>
      </div>

      {/* Amount Row */}
      <div className="amount-row">
        <div className="amount">
          <img src={images["rupee-sign-big.svg"]} alt="" />
          <div className={`value ${Theme}_bold color_deep-ocean font24`}>
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              Amount
            )}
          </div>
          <div className={`text ${Theme}_regular font14 color_deep-ocean`}>
            Due Amount
          </div>
        </div>
        <div className={`button color_white ${Theme}_semibold font16`}>
          Pay Now
        </div>
      </div>

      {/* Policy Row */}
      <div className={`policy-row`}>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          By continuing to pay , I understand and I agree with the{" "}
        </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          privacy policy
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}> and </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          terms of service
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          {" "}
          of STFares
        </div>
      </div>
    </div>
  );
}

export default UPI;
