import React, { useState, useEffect, useRef } from "react";
import "./date_Selector.css";
import images from "../../../../../Utils/importImages";

function YearSelector({
  Theme,
  Heading,
  onBlur,
  error,
  helperText,
  value,
  onChange,
  DropDownYears,
  clickedInside,
  setClickedInside,
  SetCallValidator
}) {


  const [isActive, setIsActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedYear, setSelectedYear] = useState(value);

  const ref = useRef(null);

  const handleClickInside = (dropdown) => {
    setIsActive(true);
    setClickedInside(true);
    setActiveDropdown(dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        SetCallValidator(true);
        setIsActive(false);
        setActiveDropdown(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clickedInside]);

  // Automatically open the year dropdown after selecting a month

  const handleSelectYear = (year) => {
    setSelectedYear(year);
  };

  useEffect(() => {

    if (selectedYear && isActive == true && activeDropdown === "year") {
      setActiveDropdown(null);
    }
    
  } , [selectedYear]);

  useEffect(() => {
    if (clickedInside === true) {
      onChange(selectedYear);
    }
  }, [selectedYear]);

  return (
    <div className="dateselector-wrapper" style={{width:"100%"}}>
      <div
        ref={ref}
        className={`dateselector ${isActive ? "active" : ""} ${error && "error"
          } ${Theme}`}
        onClick={() => setIsActive(true)}
      >
        <div className={`${Theme}_regular font13 headbar ${Theme} ${error && "error"}`}>
          {Heading}
        </div>

        <div className="databar">

          {/* Month Section */}
          <div onClick={() => handleClickInside("year")} className="section">
            <div className={`${Theme}_regular color_light-cement font16 d-head`}>
              {selectedYear || "Year"}
            </div>
            {activeDropdown === "year" && (
              <div className="dropdown dropdown-year">
                {Array.from(
                  { length: 101 },
                  (_, i) => new Date().getFullYear() + i
                ).map((i) => (
                  <div
                    key={i}
                    onClick={() => handleSelectYear(i)}
                    className={`${Theme}_${selectedYear == i ? "semibold" : "regular"
                      } color_light-cement font16 dropdown-item`}
                  >
                    {i}
                  </div>
                ))}
              </div>
            )}
            <img
              src={images[`dmy-arrow-${Theme === "proxima" ? "blue" : "gold"}.svg`]}
              alt="arrow"
              className={`dd-arrow ${activeDropdown === "year" && "open"}`}
            />
          </div>

        </div>
      </div>

      {error && (
        <div className={`${Theme}_regular color_error-red errorbar`}>
          {helperText}
        </div>
      )}
    </div>
  );
}

export default YearSelector;
