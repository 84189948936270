import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MAIN_PAYMENT_URL } from '../../Config/Api';
import { usePDF } from 'react-to-pdf';

function MainResultPage() {
  let dispatch = useDispatch();
  let { PaymentData, payment_Status, paymentType, BookingData } = useSelector((state) => state.HomePageMain);

  useEffect(() => {
    if (payment_Status === 'pending') {
      const redirectUrl = `${MAIN_PAYMENT_URL}id=${PaymentData.order_id}`;
      window.location.href = redirectUrl;
    }
  }, [payment_Status]);

  const { toPDF, targetRef } = usePDF({ filename: `Receipt-stfares-${PaymentData.order_id}.pdf` });

  return (
    <div style={styles.pageContainer}>
      <div style={styles.paymentContainer}>
        <h1 style={styles.heading}>Payment Details</h1>

        {paymentType === "Flight" && (
          <div style={styles.infoMessage}>
            Get your booking's status and detailed information in your <strong>My Bookings</strong> section and in the provided email address.
          </div>
        )}

        <button onClick={() => toPDF()} style={styles.downloadButton}>
          Download Receipt
        </button>

        <div style={styles.orderDetails} ref={targetRef}>
          <p><strong>Order Type:</strong> {paymentType}</p>
          <p><strong>Booking/Order Status:</strong> {BookingData.status}</p>

          {PaymentData.order_status === "tempered" ? (
            <div style={styles.tamperedMessage}>
              <h2 style={styles.errorHeading}>Order Tampered</h2>
              <p>It appears that the order details have been tampered with during the payment. Please contact customer support for assistance.</p>
            </div>
          ) : (
            <>
              <h2
                style={
                  PaymentData.order_status === "Success"
                    ? styles.successHeading
                    : styles.errorHeading
                }
              >
                {PaymentData.order_status === "Success"
                  ? "Payment Successful"
                  : "Payment Failed"}
              </h2>
              <div style={styles.thankYouMessage}>
                {PaymentData.order_status === "Success"
                  ? "Thank you for choosing us for your booking!"
                  : "We apologize for the inconvenience. Please check your payment details and try again."}
              </div>

              <h3 style={styles.summaryHeading}>Payment Summary</h3>
              <ul style={styles.summaryList}>
                <li><strong>Transaction Amount:</strong> {PaymentData.amount}</li>
                <li><strong>Order ID:</strong> {PaymentData.order_id}</li>
                <li><strong>Tracking ID:</strong> {PaymentData.transaction_no}</li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const styles = {
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f4f7',
    padding: '40px 20px',
    fontFamily: 'Arial, sans-serif',
  },
  paymentContainer: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
    textAlign: 'left',
  },
  heading: {
    fontSize: '28px',
    color: '#333',
    marginBottom: '20px',
  },
  infoMessage: {
    backgroundColor: '#e7f3fe',
    borderLeft: '5px solid #007BFF',
    padding: '10px',
    marginBottom: '20px',
    fontSize: '16px',
    borderRadius: '4px',
  },
  downloadButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    padding: '10px 20px',
    margin: '20px 0',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
  },
  orderDetails: {
    marginTop: '20px',
  },
  tamperedMessage: {
    backgroundColor: '#fff3f3',
    borderLeft: '5px solid #ff4d4f',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '4px',
  },
  errorHeading: {
    color: '#ff4d4f',
    fontWeight: 'bold',
  },
  successHeading: {
    color: '#4CAF50',
    fontWeight: 'bold',
  },
  thankYouMessage: {
    fontSize: '18px',
    marginTop: '10px',
    marginBottom: '20px',
  },
  summaryHeading: {
    fontSize: '22px',
    margin: '10px 0',
  },
  summaryList: {
    listStyle: 'none',
    padding: '0',
  },
};

export default MainResultPage;
