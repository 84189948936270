import React from "react";
import "./Editbar.css";
import images from "../../../../../Utils/importImages";

function EditBar({ Theme, Heading, showEdit, onClickEdit }) {
  return (
    <div className="editBar-heading">
      <div className={`${Theme}_semibold font16 color_dark-purple`}>
        {Heading}
      </div>
      {showEdit && (
        <div className="edit" onClick={() => onClickEdit()}>
          <div className={`${Theme}_semibold color_endeavour font16`}>Edit</div>
          <img src={images["edit-bank.svg"]} alt="" />
        </div>
      )}
    </div>
  );
}

export default EditBar;
