import React, { useState, useEffect } from "react";
import "./NetBanking.css";
import images from "../../../../Utils/importImages";
import { InputAdornment, SvgIcon, TextField } from "@mui/material";
import EditBar from "./Components/EditBar";
import { useDispatch, useSelector } from "react-redux";
import { Clicked_PayNow } from "../../../../Redux/Slices/Main_HomePage_Slice/HomePage_SLICE";

// Search Icon
const CustomSearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8253 14.3821C8.39736 14.3821 5.61843 11.6031 5.61843 8.17516C5.61843 4.74718 8.39736 1.96826 11.8253 1.96826C15.2533 1.96826 18.0322 4.74718 18.0322 8.17516C18.0322 11.6031 15.2533 14.3821 11.8253 14.3821Z"
      stroke="#626262"
      strokeWidth="1.37931"
      strokeMiterlimit="10"
    />
    <path
      d="M7.2749 12.7249L1.96801 18.0317"
      stroke="#626262"
      strokeWidth="1.37931"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// Expand Icon
function ExpandIcon(props) {
  return (
    <SvgIcon {...props} style={{ color: "black", fontSize: props.fontSize }}>
      <path d="M4 10h3v7H4zm6.5 0h3v7h-3zM2 19h20v3H2zm15-9h3v7h-3zm-5-9L2 6v2h20V6z" />
    </SvgIcon>
  );
}

function NetBanking({ Theme, Amount, paymentOptionsAvailable }) {

  let dispatch = useDispatch();
  let {payNowLoading} = useSelector((state) => state.HomePageMain);

  let [continueButtonError , setcontinueButtonError] = useState(false);

  const [selectedBank, setSelectedBank] = useState("");
  const [selectedBankError, setSelectedBankError] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [filteredBanks, setFilteredBanks] = useState(
    paymentOptionsAvailable?.NetBanking || []
  ); // State for filtered banks

  // Debounce logic with useEffect
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Filter banks based on search term
      const filtered = paymentOptionsAvailable?.NetBanking.filter((bank) =>
        bank.cardName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredBanks(filtered || []);
    }, 300); // 300ms debounce delay

    return () => clearTimeout(delayDebounceFn); // Cleanup on unmount or searchTerm change
  }, [searchTerm, paymentOptionsAvailable]);

  const onChangeBankName = (event) => {
    setSelectedBankError("");
    setSearchTerm(event.target.value); // Update search term on change
  };

  const validateBankName = () => {
    // Validation logic
    if(selectedBank == ""){
      setcontinueButtonError(true)
      return false;
    }

    return true;
  };

  let onClickEdit = () => {
    setSelectedBank("");
  };


  useEffect(() => {
    let timeoutId;

    if (continueButtonError === true) {
      // Set a timeout to dispatch the action after 2 seconds
      timeoutId = setTimeout(() => {
        setcontinueButtonError(false)
      }, 2000); // 2000 milliseconds = 2 seconds
    }

    // Clean up the timeout if the component unmounts or the condition changes
    return () => clearTimeout(timeoutId);
  }, [continueButtonError]);
    
  let submitForm = () => {

    // validate the card payment 

    let isValidatedBankName = validateBankName()
  
    if(isValidatedBankName == true){

 
        let bankDetails = {
          card_type : "NBK",
          card_name : selectedBank,
        }
      
      dispatch(Clicked_PayNow("Net Banking" , bankDetails))
    }
  }



  return (
    <div className="netBanking">

      {
        selectedBank == "" &&
      <TextField
        autoComplete="off"
        onBlur={validateBankName}
        error={!!selectedBankError}
        helperText={selectedBankError}
        value={searchTerm}
        onChange={onChangeBankName} // Handle change
        id="outlined-basic"
        label="Search your Bank"
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme === "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontWeight: "400",
            "&.Mui-focused": {
              color: Theme === "averta" ? "#C2A07C" : "#0057B7", // Color when focused
            },
          },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CustomSearchIcon />
            </InputAdornment>
          ),
        }}
      />
      }

      <EditBar
        Theme={Theme}
        Heading={(filteredBanks.length == 0 ? "NO BANK FOUND" : (selectedBank == "" ? "SELECT YOUR BANK" : "SELECTED BANK"))}
        showEdit={selectedBank !== ""}
        onClickEdit={onClickEdit}
      />

      {selectedBank == "" ? (
        <div className="banks-container">
          {filteredBanks.length > 0 && filteredBanks.map((item, id) => {
            if (item.status !== "ACTI") return null;

            return (
              <div
                className="bank"
                key={id}
                onClick={() => setSelectedBank(item.cardName)}
              >
                <img
                  src={
                    images[
                      item.cardName === selectedBank
                        ? "selected-blue-payment.svg"
                        : "non-selected-blue-payment.svg"
                    ]
                  }
                  alt=""
                />
                <ExpandIcon fontSize="medium" />
                <div className={`${Theme}_regular font16 color_deep-ocean`}>
                  {item.cardName}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="banks-container" style={{ border: "none" }}>
          <div
            className="bank"
            style={{ border: "1px solid #c4c4c4", borderRadius: "5px" }}
          >
            <img src={images["selected-blue-payment.svg"]} alt="" />
            <ExpandIcon fontSize="medium" />
            <div className={`${Theme}_regular font16 color_deep-ocean`}>
              {selectedBank}
            </div>
          </div>
        </div>
      )}

      {selectedBank !== "" && (
        <div
          className={`net-banking-msg ${Theme}_regular font15 color_deep-ocean`}
        >
          You'll be securely redirected to Net Banking to complete your payment.
          Please do not refresh the page or click back while your payment is
          being processed.
        </div>
      )}

      {/* Amount Row */}

      {
        continueButtonError == true && 
        <div
        className={`net-banking-msg-red ${Theme}_regular font15 color_white`}
      >
        Please Select a Bank to Continue Net banking
      </div>
      }
      <div className="amount-row">
        <div className="amount">
          <img src={images["rupee-sign-big.svg"]} alt="" />
          <div className={`value ${Theme}_bold color_deep-ocean font24`}>
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              Amount
            )}
          </div>
          <div className={`text ${Theme}_regular font14 color_deep-ocean`}>
            Due Amount
          </div>
        </div>
        <div className={`button color_white ${Theme}_semibold font16`} onClick={() => (payNowLoading === true || continueButtonError == true) ? null : submitForm()}>
          Pay Now {(payNowLoading == true || continueButtonError == true) && <div className={`loader ${Theme}`}></div> }
        </div>
      </div>

      {/* Policy Row */}
      <div className={`policy-row`}>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          By continuing to pay , I understand and I agree with the{" "}
        </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          privacy policy
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}> and </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          terms of service
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          {" "}
          of STFares
        </div>
      </div>
    </div>
  );
}

export default NetBanking;
