import React, { useEffect, useState } from "react";
import "./CardPayment.css";
import { SvgIcon, TextField } from "@mui/material";
import images from "../../../../Utils/importImages";
import MonthSelector from "./Components/MonthSelector";
import YearSelector from "./Components/YearSelector";
import jsonp from "jsonp";
import OptionsSelector from "./Components/OptionsSelector";
import { useDispatch, useSelector } from "react-redux";
import { Clicked_PayNow } from "../../../../Redux/Slices/Main_HomePage_Slice/HomePage_SLICE";

function ExpandIcon(props) {
  return (
    <SvgIcon
      {...props}
      style={{ color: props.color, fontSize: props.fontSize }}
    >
      <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </SvgIcon>
  );
}

function CardPayment({ Theme, Amount, paymentOptionsAvailable }) {
  // console.log("paymentOptionsAvailable is : ", paymentOptionsAvailable);

  let dispatch = useDispatch();
  let {payNowLoading} = useSelector((state) => state.HomePageMain);

  let [CardOption, setCardOption] = useState(0);

  let [CardNumber, setCardNumber] = useState("");
  let [CardHolderName, setCardHolderName] = useState("");
  let [CardHolderBankname, setCardHolderBankname] = useState("");
  let [CardName, setCardName] = useState("");
  let [ExpiryMonth, setExpiryMonth] = useState("");
  let [ExpiryYear, setExpiryYear] = useState("");
  let [CVV, setCVV] = useState("");

  // console.log("ExpiryYear is : " , ExpiryYear);
  
  let [CardNumberError, setCardNumberError] = useState("");
  let [CardHolderNameError, setCardHolderNameError] = useState("");
  let [CardHolderBanknameError, setCardHolderBanknameError] = useState("");
  let [CardNameError, setCardNameError] = useState("");
  let [ExpiryMonthError, setExpiryMonthError] = useState("");
  let [ExpiryYearError, setExpiryYearError] = useState("");
  let [CVVError, setCVVError] = useState("");

  let onChangeCardNumber = (info) => {
    setCardNumberError("");
    setCardNumber(info.target.value);
  };

  const validateCardNumber = () => {
    if (CardNumber === "") {
      setCardNumberError("Card Number Can't be Empty");
      return false;
    } else if (CardNumber.length > 20) {
      setCardNumberError("Card Number max should contain 20 digits");
      return false;
    }
    return true;
  };

  let onChangeCardHolderName = (info) => {
    setCardHolderNameError("");
    setCardHolderName(info.target.value);
  };

  const validateCardHolderName = () => {
    if (CardHolderName === "") {
      setCardHolderNameError("Card Holder Name Can't be Empty");
      return false;
    } else if (!/^[A-Za-z ]+$/.test(CardHolderName)) {
      setCardHolderNameError(
        "Name on Card should contain only alphabets and spaces"
      );
      return false;
    }
    return true;
  };

  let onChangeCardHolderBankname = (info) => {
    setCardHolderBanknameError("");
    setCardHolderBankname(info.target.value);
  };

  const validateCardHolderBankName = () => {
    if (CardHolderBankname === "") {
      setCardHolderBanknameError("Card's Bank Name Can't be Empty");
      return false;
    } else if (!/^[A-Za-z ]+$/.test(CardHolderBankname)) {
      setCardHolderBanknameError(
        "Card's Bank should contain only alphabets and spaces"
      );
      return false;
    }
    return true;
  };

  let onChangeCardName = (info) => {
    setCardNameError("");
    setCardName(info == null ? "" : info.cardName);
    // console.log("info is : ", info);
  };
  
  const validateCardName = () => {
    if (CardName === "") {
      setCardNameError("Card Name Can't be Empty");
      return false;
    }
    return true;
  };

  let [clickedInsideMonth, setClickedInsideMonth] = useState(false);
  let [callMonthValidator, setcallMonthValidator] = useState(false);

  useEffect(() => {
    if (clickedInsideMonth == true && callMonthValidator == true) {
      validateMonth(ExpiryMonth);
    }
  }, [clickedInsideMonth, callMonthValidator]);

  let onChangeExpiryMonth = (info) => {
    setExpiryMonth(info);
    setExpiryMonthError("");
  };

  const validateMonth = () => {
    if (ExpiryMonth === "") {
      setExpiryMonthError("Expiry Month Can't be Empty");
      return false;
    }
    return true;
  };

  let [clickedInsideYear, setClickedInsideYear] = useState(false);
  let [callYearValidator, setcallYearValidator] = useState(false);

  useEffect(() => {
    if (clickedInsideYear == true && callYearValidator == true) {
      validateYear(ExpiryYear);
    }
  }, [clickedInsideYear, callYearValidator]);

  let onChangeExpiryYear = (info) => {
    
    setExpiryYear(info);
    setExpiryYearError("");
  };

  const validateYear = () => {
    if (ExpiryYear === "") {
      setExpiryYearError("Expiry Year Can't be Empty");
      return false;
    }
    return true;
  };

  let onChangeCVVNumber = (info) => {
    setCVVError("");
    setCVV(info.target.value);
  };

  const validateCVVNumber = () => {
    if (CVV === "") {
      setCVVError("Card CVV Can't be Empty");
      return false;
    } else if (!/^\d{3}$/.test(CVV)) {
      setCVVError("CVV Number should be of 3 digits");
      return false;
    }
    return true;
  };

 
  let validateCardExpiry = (expiryMonth, expiryYear) => {
    // Get the current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-based, so we add 1
  
    // Convert expiryYear to a full year (e.g., '23' to '2023')
    const fullExpiryYear = expiryYear.length === 2 ? '20' + expiryYear : expiryYear;
  
    // Compare the year and month
    if (fullExpiryYear < currentYear) {
      console.log("Card is expired");
      return false; // Card is expired
    } else if (fullExpiryYear == currentYear && expiryMonth < currentMonth) {
      console.log("Card is expired");
      return false; // Card is expired
    }
  
    console.log("Card is valid");
    return true; // Card is valid
  };

  
  let validateCardNumberAdvance = (cardNumber) => {
    const cardPatterns = [
      { name: "Visa", pattern: /^4[0-9]{12}(?:[0-9]{3})?$/ },           // Visa cards
      { name: "MasterCard", pattern: /^5[1-5][0-9]{14}$/ },             // MasterCard
      { name: "AmEx", pattern: /^3[47][0-9]{13}$/ },                    // American Express
      { name: "Discover", pattern: /^6(?:011|5[0-9]{2})[0-9]{12}$/ },   // Discover
      { name: "JCB", pattern: /^(?:2131|1800|35\d{3})\d{11}$/ },        // JCB
      { name: "Diners Club", pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/ },  // Diners Club
      { name: "RuPay", pattern: /^6(?:0|5|8)[0-9]{14}$/ },              // RuPay
      { name: "Maestro", pattern: /^(?:5[0678]|6[0-9])[0-9]{12,19}$/ }, // Maestro
      { name: "UnionPay", pattern: /^62[0-9]{14,17}$/ },                // UnionPay
      { name: "Mir", pattern: /^220[0-4][0-9]{12}$/ }                   // Mir
    ];
  
    // Iterate over the card patterns to match the card number
    for (let card of cardPatterns) {
      if (card.pattern.test(cardNumber)) {
        console.log(`Valid card detected: ${card.name}`);
        return true; // Card is valid
      }
    }
  
    console.log("Invalid card number");
    return false; // No valid pattern was matched
  };
  
  let submitForm = () => {

    // validate the card payment 

    let isValidatedCardNumber = validateCardNumber()
    let isValidatedCardHolderName = validateCardHolderName()
    let isValidatedCardsBankName = validateCardHolderBankName()
    let isValidCardName = validateCardName()
    let isValidMonth = validateMonth()
    let isValidYear = validateYear()
    let isValidCVVNumber = validateCVVNumber()

    if(isValidatedCardNumber && isValidatedCardHolderName && isValidatedCardsBankName && isValidCardName && isValidMonth && isValidYear && isValidCVVNumber){

      // do advance validation 

      // 1 . validate card Number

      let isCardValidatedAdvance = validateCardNumberAdvance(CardNumber);
      if(isCardValidatedAdvance == false){
        setCardNumberError("Invalid Card Number");
        return;
      }
      setCardNumberError("")

      let isCardNotExpired = validateCardExpiry(ExpiryMonth , ExpiryYear);
      if(isCardNotExpired == false){
        setExpiryMonthError("Card is Expired");
        setExpiryYearError(" ");
        return;
      }
      setExpiryMonthError("");
      setExpiryYearError("");

        let CardParas = {
          card_type : CardOption == 0 ? 'CRDC' : 'DBCRD',
          card_name : CardName,
          card_number : CardNumber,
          expiry_month : ExpiryMonth,
          expiry_year : ExpiryYear,
          cvv_number : CVV,
          issuing_bank : CardHolderBankname,
        }
      
      dispatch(Clicked_PayNow(CardOption == 0 ? "Credit Card" : "Debit Card" , CardParas))
    }
  }

  return (
    <div className="cardPayment">
      <div className="card-types">
        <div className="card-option" onClick={() => setCardOption(0)}>
          <img
            src={
              images[
                CardOption === 0
                  ? "selected-blue-payment.svg"
                  : "non-selected-blue-payment.svg"
              ]
            }
            alt=""
          />
          <div className={`${Theme}_semibold color_deep-ocean font14`}>
            Credit Card
          </div>
        </div>
        <div className="card-option" onClick={() => setCardOption(1)}>
          <img
            src={
              images[
                CardOption === 1
                  ? "selected-blue-payment.svg"
                  : "non-selected-blue-payment.svg"
              ]
            }
            alt=""
          />
          <div className={`${Theme}_semibold color_deep-ocean font14`}>
            Debit Card / ATM Card
          </div>
        </div>
      </div>

      <TextField
        autoComplete="off"
        onBlur={validateCardNumber} // Validate on blur
        error={!!CardNumberError}
        helperText={CardNumberError}
        value={CardNumber}
        onChange={onChangeCardNumber}
        id="outlined-basic"
        label="Card Number"
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontWeight: "400",
            "&.Mui-focused": {
              color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
            },
          },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
          },
        }}
      />

      <div className="cards-logo">
        {[
          "visa.svg",
          "mastercard.svg",
          "maestro.svg",
          "american-express.svg",
          "rupay.svg",
          "diners-club.svg",
        ].map((item) => {
          return <img src={images[item]} alt="" />;
        })}
      </div>

      <TextField
        autoComplete="off"
        onBlur={validateCardHolderName} // Validate on blur
        error={!!CardHolderNameError}
        helperText={CardHolderNameError}
        value={CardHolderName}
        onChange={onChangeCardHolderName}
        id="outlined-basic"
        label="Card Holder Name"
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontWeight: "400",
            "&.Mui-focused": {
              color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
            },
          },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
          },
        }}
      />

      <div className="card-type-row">
        <TextField
          autoComplete="off"
          onBlur={validateCardHolderBankName} // Validate on blur
          error={!!CardHolderBanknameError}
          helperText={CardHolderBanknameError}
          value={CardHolderBankname}
          onChange={onChangeCardHolderBankname}
          id="outlined-basic"
          label="Card's Bank Name"
          variant="outlined"
          sx={{
            width: "50%",
            "& .MuiInputLabel-root": {
              fontSize: "16px",
              fontFamily: `var(--font-family-${
                Theme == "proxima"
                  ? `${Theme}_nova-regular`
                  : `${Theme}-regular`
              })`,
              fontStyle: "normal",
              fontWeight: "400",
              "&.Mui-focused": {
                color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
              },
            },
            "& .MuiOutlinedInput-root": {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
            },
          }}

        />

        <OptionsSelector
          width="50%"
          onChange={onChangeCardName}
          value={CardName}
          onBlurFunc={validateCardName}
          Error={CardNameError}
          Theme={Theme}
          options={
            CardOption === 0
              ? paymentOptionsAvailable.CreditCard
              : paymentOptionsAvailable.DebitCard
          }
          showIcon={true}
          showBottomIcon={false}
          name={"Card Name"}
          IconComponent={
            <ExpandIcon
              color={Theme == "proxima" ? "#0057B7" : "#976c41"}
              fontSize="medium"
            />
          }
          mainkey={"cardName"}
        />
      </div>

      <div className="bottom-inputs">
        <MonthSelector
          clickedInside={clickedInsideMonth}
          setClickedInside={setClickedInsideMonth}
          SetCallValidator={setcallMonthValidator}
          Theme={Theme}
          Heading={"Expiry Month"}
          onBlur={validateMonth} // Validate on blur
          error={!!ExpiryMonthError}
          helperText={ExpiryMonthError}
          value={ExpiryMonth}
          onChange={onChangeExpiryMonth}
        />

        <YearSelector
          clickedInside={clickedInsideYear}
          setClickedInside={setClickedInsideYear}
          SetCallValidator={setcallYearValidator}
          Theme={Theme}
          Heading={"Expiry Year"}
          onBlur={validateYear} // Validate on blur
          error={!!ExpiryYearError}
          helperText={ExpiryYearError}
          value={ExpiryYear}
          onChange={onChangeExpiryYear}
        />

        <TextField
          autoComplete="off"
          onBlur={validateCVVNumber} // Validate on blur
          error={!!CVVError}
          helperText={CVVError}
          value={CVV}
          onChange={onChangeCVVNumber}
          id="outlined-basic"
          label="CVV Number"
          variant="outlined"
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              fontSize: "16px",
              fontFamily: `var(--font-family-${
                Theme == "proxima"
                  ? `${Theme}_nova-regular`
                  : `${Theme}-regular`
              })`,
              fontStyle: "normal",
              fontWeight: "400",
              "&.Mui-focused": {
                color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
              },
            },
            "& .MuiOutlinedInput-root": {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
            },
          }}
        />
      </div>

      <div className="amount-row">
        <div className="amount">
          <img src={images["rupee-sign-big.svg"]} alt="" />
          <div className={`value ${Theme}_bold color_deep-ocean font24`}>
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              Amount
            )}
          </div>
          <div className={`text ${Theme}_regular font14 color_deep-ocean`}>
            Due Amount
          </div>
        </div>
        <div className={`button color_white ${Theme}_semibold font16`} onClick={() => payNowLoading === true ? null : submitForm()}>
          Pay Now {payNowLoading == true && <div className={`loader ${Theme}`}></div> }
        </div>
      </div>

      <div className={`policy-row`}>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          By continuing to pay , I understand and i agree with the{" "}
        </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          privacy policy
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}> and </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          terms of service
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          {" "}
          of STFares{" "}
        </div>
      </div>
    </div>
  );
}

export default CardPayment;
