import axios from "axios";
import { MAIN_URL } from "../../Config/Api";

export async function getPaymentData(id) {

  try {

    const response = await axios.get(
      `${MAIN_URL}payments/${id}`
    );

    return response;
  } catch (error) {
    console.log("the api throw error is  : ", error);
    throw error;
  }

}

export async function callPriceVerificationFlight(params) {

  try {

    const response = await axios.post(
      `${MAIN_URL}flights/STFARES/paynow_phase_1`,
      params
    );

    return response;
  } catch (error) {
    console.log("the api throw error is  : ", error);
    throw error;
  }

}


export async function callPaymentGateway(params){
  try {

    const response = await axios.post(
      `${MAIN_URL}payment-gateways/seamless/cc-avenue/start`,
      params
    );

    return response;
  } catch (error) {
    console.log("the api throw error is  : ", error);
    throw error;
  }
}