import React, { useState } from 'react'
import "./GooglePay.css";
import EditBar from './Components/EditBar';
import { InputAdornment, TextField } from '@mui/material';
import images from '../../../../Utils/importImages';

const CustomSearchIcon = () => (
  <img src={images['google-icon.png']} className='google-pay-icon'/>
);

function GooglePay({ Theme, Amount, paymentOptionsAvailable }) {

  let [upiId, setUpiId] = useState("");
  let [upiIdError, setUpiIdError] = useState("");

  let onChangeUPIId = (info) => {
    setUpiIdError("");
    setUpiId(info.target.value);
  };

  const validateUPIId = () => {
    if (/^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/.test(upiId)) {
      setUpiIdError("Invalid Upi Id");
    }
  };

  return (
    <div className='gpay-container'>
      
      <EditBar
        Theme={Theme}
        Heading={"ENTER YOUR GOOGLE PAY UPI ID"}
        showEdit={false}
        onClickEdit={null}
      />

      <TextField
        autoComplete="off"
        onBlur={validateUPIId}
        error={!!upiIdError}
        helperText={upiIdError}
        value={upiId}
        onChange={onChangeUPIId} // Handle change
        id="outlined-basic"
        label="Google Pay Id"
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme === "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontWeight: "400",
            "&.Mui-focused": {
              color: Theme === "averta" ? "#C2A07C" : "#0057B7", // Color when focused
            },
          },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
            },
          },
        }}

        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CustomSearchIcon />
            </InputAdornment>
          ),
        }}

      />

      <div className="upi-instructions-row">
        <div
          className={`upi-instructions-row-item ${Theme}_regular font14 color_deep-ocean`}
        >
          <img src={images["list-icon.svg"]} alt="" />
          Enter your registered VPA.
        </div>
        <div
          className={`upi-instructions-row-item ${Theme}_regular font14 color_deep-ocean`}
        >
          <img src={images["list-icon.svg"]} alt="" />
          Receive payment request on bank app
        </div>
        <div
          className={`upi-instructions-row-item ${Theme}_regular font14 color_deep-ocean`}
        >
          <img src={images["list-icon.svg"]} alt="" />
          Authorize payment request.
        </div>
      </div>

      {/* Amount Row */}
      <div className="amount-row">
        <div className="amount">
          <img src={images["rupee-sign-big.svg"]} alt="" />
          <div className={`value ${Theme}_bold color_deep-ocean font24`}>
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              Amount
            )}
          </div>
          <div className={`text ${Theme}_regular font14 color_deep-ocean`}>
            Due Amount
          </div>
        </div>
        <div className={`button color_white ${Theme}_semibold font16`}>
          Pay Now
        </div>
      </div>

      {/* Policy Row */}
      <div className={`policy-row`}>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          By continuing to pay , I understand and I agree with the{" "}
        </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          privacy policy
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}> and </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          terms of service
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          {" "}
          of STFares
        </div>
      </div>


    </div>
  )
}

export default GooglePay
