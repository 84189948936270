import React from "react";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import "./OptionsSelector.css";

function OptionsSelector({
  Theme, IconComponent, name, onChange, value, width, options, mainkey, onBlurFunc, Error , showIcon , showBottomIcon
}) {
  
  console.log("The options are: ", options);
  
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{
        width: width,
      }}
      value={value}
      options={options}
      autoHighlight
      onChange={(event, newValue) => onChange(newValue)}
      popupIcon={showIcon == true ? IconComponent : null}
      getOptionLabel={(option) => option[mainkey] || value} // Ensure it returns a string
      onBlur={onBlurFunc}
      renderOption={(props, option) => {
        if (option.status !== "ACTI") return null; // Skip inactive options
        return (
          <Box
          key={option[mainkey]} // Pass the key explicitly
            component="li"
            sx={{
              fontSize: "16px",
              fontFamily: `var(--font-family-${
                Theme === "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
              })`,
              fontStyle: "normal",
              fontWeight: "400",
              "& > img": { mr: 2, flexShrink: 0 }
            }}
            {...props}
          >
            {option[mainkey]} {/* Display the main key value */}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          // autoComplete="off"
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              fontSize: "16px",
              fontFamily: `var(--font-family-${
                Theme === "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
              })`,
              fontStyle: "normal",
              fontWeight: "400",
              "&.Mui-focused": {
                color: Theme === "averta" ? "#C2A07C" : "#0057B7", // Color when focused
              },
            },
            "& .MuiOutlinedInput-root": {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme === "averta" ? "#C2A07C" : "#0057B7",
              },
            },
          }}
          {...params}
          label={name}
          onBlur={onBlurFunc}
          InputProps={{
            ...params.InputProps,
            startAdornment: showBottomIcon ? (
              <InputAdornment position="start">
                {IconComponent}
              </InputAdornment>
            ) : null, // Only render if both conditions are true
          }}


          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // Disable autocomplete and autofill
          }}
          error={!!Error}
          helperText={Error}
        />
      )}
    />
  );
}

export default OptionsSelector;
