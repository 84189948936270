import React, { useEffect, useState } from 'react'
import "./Wallets.css"
import EditBar from './Components/EditBar';
import images from '../../../../Utils/importImages';
import { Clicked_PayNow } from '../../../../Redux/Slices/Main_HomePage_Slice/HomePage_SLICE';
import { useDispatch, useSelector } from 'react-redux';


function Wallets({ Theme, Amount, paymentOptionsAvailable }) {

  console.log("paymentOptionsAvailableis : " , paymentOptionsAvailable);
  
  let dispatch = useDispatch();

  let {payNowLoading} = useSelector((state) => state.HomePageMain);

  let [continueButtonError , setcontinueButtonError] = useState(false);

  const [selectedWallet, setSelectedWallet] = useState(null);

  const validateWallet = () => {
       // Validation logic
       if(selectedWallet == null){
        setcontinueButtonError(true)
        return false;
      }
  
      return true;
  };

  let onClickEdit = () => {
    setSelectedWallet(null);
  };

  useEffect(() => {
    let timeoutId;

    if (continueButtonError === true) {
      // Set a timeout to dispatch the action after 2 seconds
      timeoutId = setTimeout(() => {
        setcontinueButtonError(false)
      }, 2000); // 2000 milliseconds = 2 seconds
    }

    // Clean up the timeout if the component unmounts or the condition changes
    return () => clearTimeout(timeoutId);
  }, [continueButtonError]);

  let submitForm = () => {

    // validate the card payment 

    let isValidatedWallet = validateWallet()
  
    if(isValidatedWallet == true){

        let bankDetails = {
          card_type : "WLT",
          card_name : selectedWallet.cardName,
        }
      
      dispatch(Clicked_PayNow("Wallet" , bankDetails))
    }
  }

  
  return (
    <div className='wallet-section'>

      <EditBar
        Theme={Theme}
        Heading={((selectedWallet == null ? "SELECT YOUR WALLET" : "SELECTED WALLET"))}
        showEdit={selectedWallet !== null}
        onClickEdit={onClickEdit}
      />

      {selectedWallet == null ? (
        <div className="wallet-container">
          {paymentOptionsAvailable.Wallet.length > 0 && paymentOptionsAvailable.Wallet.map((item, id) => {
            if (item.status !== "ACTI") return null;

            return (
              <div
                className="wallet"
                key={id}
                onClick={() => setSelectedWallet(item)}
              >
                <img
                  src={
                    images[
                      item.cardName === selectedWallet?.cardName
                        ? "selected-blue-payment.svg"
                        : "non-selected-blue-payment.svg"
                    ]
                  }
                  alt=""
                />
                <img src={images['wallet.png']} className='wallet-logo' alt=""  />
                <div className={`${Theme}_regular font16 color_deep-ocean`}>
                  {item.cardName}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="wallet-container" style={{ border: "none" }}>
          <div
            className="wallet"
            style={{ border: "1px solid #c4c4c4", borderRadius: "5px" }}
          >
            <img src={images["selected-blue-payment.svg"]} alt="" />
            <img src={images['wallet.png']} className='wallet-logo' alt=""  />
            <div className={`${Theme}_regular font16 color_deep-ocean`}>
              {selectedWallet?.cardName}
            </div>
          </div>
        </div>
      )}

      {selectedWallet !== null && (
        <div
          className={`wallet-msg ${Theme}_regular font15 color_deep-ocean`}
        >
          You'll be securely redirected to {selectedWallet?.cardName} to complete your payment.
          Please do not refresh the page or click back while your payment is
          being processed.
        </div>
      )}

{
        continueButtonError == true && 
        <div
        className={`wallet-msg-red ${Theme}_regular font15 color_white`}
      >
        Please Select a Wallet to Continue Wallet Payment
      </div>
      }
      {/* Amount Row */}
      <div className="amount-row">
        <div className="amount">
          <img src={images["rupee-sign-big.svg"]} alt="" />
          <div className={`value ${Theme}_bold color_deep-ocean font24`}>
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              Amount
            )}
          </div>
          <div className={`text ${Theme}_regular font14 color_deep-ocean`}>
            Due Amount
          </div>
        </div>
        <div className={`button color_white ${Theme}_semibold font16`} onClick={() => (payNowLoading === true || continueButtonError == true) ? null : submitForm()}>
          Pay Now {(payNowLoading == true || continueButtonError == true) && <div className={`loader ${Theme}`}></div> }
        </div>
      </div>

      {/* Policy Row */}
      <div className={`policy-row`}>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          By continuing to pay , I understand and I agree with the{" "}
        </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          privacy policy
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}> and </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          terms of service
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          {" "}
          of STFares
        </div>
      </div>

    </div>
  )
}

export default Wallets
